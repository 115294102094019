
  export const textFieldOutline={
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: (theme:any) => theme.palette.grey[400], // Set border color
      },
      '&:hover fieldset': {
        borderColor: (theme:any) =>theme.palette.primary.main, // Keep border color on hover
      },
      '&.Mui-focused fieldset': {
        borderColor: (theme:any) =>theme.palette.secondary.main, // Border color when focused
      },
      '&.Mui-disabled fieldset': {
        borderColor: (theme:any) =>theme.palette.grey[400], // Change border color when disabled
      },
    },
    '& .Mui-disabled': {
      color: (theme:any) =>theme.palette.grey[400],
      '-webkit-text-fill-color': (theme:any) =>theme.palette.grey[400], // Change text color when disabled
    },
    '& .MuiInputLabel-root': {
        color: (theme:any) =>theme.palette.primary.main, 
        '&.Mui-disabled': {
          color: (theme:any) =>theme.palette.grey[400], 
          '-webkit-text-fill-color': (theme:any) =>theme.palette.grey[400], 
          '-webkit-opacity': 1,
        },
      },
  }

export const switchColor={
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: (theme:any)=>theme.palette.primary.main, // Primary color for checked thumb
      '&:hover': {
        backgroundColor: (theme:any)=>theme.palette.primary.main + '20', // Hover effect for primary thumb
      },
    },
    '& .MuiSwitch-switchBase': {
      color: (theme:any)=>theme.palette.secondary.main, // Secondary color for unchecked thumb
      '&:hover': {
        backgroundColor:(theme:any)=> theme.palette.secondary.main + '20', // Hover effect for secondary thumb
      },
    },
  
    '& .Mui-checked + .MuiSwitch-track': {
      backgroundColor: (theme:any)=>theme.palette.primary.main, // Track color when checked
    },
  }