import parsePhoneNumberFromString, { CountryCode } from 'libphonenumber-js';
import { getValueBySubPath } from './CustomPropertySupport/helperFunctions';

//==============================================================
// Types
//==============================================================

type CustomValidator = (instance: any, row: any, value: string) => true | string;

//==============================================================
// private variables
//==============================================================

const countryCdKey = 'CountryCd';

const getCountryCd = (row: any) => {
  let country: any = getValueBySubPath(row ?? {}, countryCdKey);
  while (typeof country === 'object') {
    const k = Object.keys(country)[0];
    const key = k !== undefined ? k : '';
    country = country[key];
  }
  if (typeof country === 'string') {
    return country as CountryCode;
  }
  return undefined;
};

//==============================================================
// CustomValidationSupport Class
//==============================================================

export class CustomValidationSupport {
  isValidPhoneNumber: CustomValidator = (instance, row, value) => {
    if (!value) return 'value is missing';

    const previousKey = `prev-${instance.key}`;
    let countryCd = getCountryCd(row);
    let phoneNumber = parsePhoneNumberFromString(value, countryCd);

    if (!phoneNumber) return `invalid-${instance.key}-CountryCd`;

    if (phoneNumber.country !== countryCd) {
      if (row[previousKey] === undefined && phoneNumber.country) {
        // on initial set-up
        row[countryCdKey] = phoneNumber.country;
        countryCd = phoneNumber.country;
      }
      // re-evaluating the phone number using new country code
      phoneNumber = parsePhoneNumberFromString(phoneNumber.nationalNumber, countryCd) ?? phoneNumber;
    }

    const formattedNumber = phoneNumber.formatInternational().replace(/ /g, '-');
    if (row[previousKey] !== formattedNumber) {
      row[previousKey] = formattedNumber;
      instance.setValue(formattedNumber);
    }

    return phoneNumber.isValid() ? true : `invalid-${instance.key}`;
  };
}
