
import { useTheme } from '@mui/material/styles';

function IconBicycle(props:any) {
  const theme = useTheme();
  
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || '20'}
      height={props.height || '20'}
      viewBox="0 0 640 512"
      aria-labelledby="icon-bicycle-title"
      role="img"
    >
      <title id="icon-bicycle-title">Bicycle Icon</title>
      <defs>
        <linearGradient id="bicycleGradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" style={{ stopColor: theme.palette.primary.main, stopOpacity: 1 }} />
          <stop offset="100%" style={{ stopColor: theme.palette.secondary.main, stopOpacity: 1 }} />
        </linearGradient>
      </defs>
      <path fill="none" d="M0 0h640v512H0z" data-name="background" />
      <path
        fill="url(#bicycleGradient)"
        d="M312 32c-13.3 0-24 10.7-24 24s10.7 24 24 24h25.7l34.6 64h-149.4l-27.4-38c-2.3-3.2-9.6-6.5-17.3-6.5h-56c-13.3 0-24 10.7-24 24s10.7 24 24 24h43.7l22.1 30.7-26.6 53.1c-10-2.5-20.5-3.8-31.2-3.8C57.3 224 0 281.3 0 352s57.3 128 128 128c65.3 0 119.1-48.9 127-112h49c8.5 0 16.3-4.5 20.7-11.8l84.8-143.5 21.7 40.1c-17.1 22.5-35.5 58.2-35.5 98.2 0 70.7 57.3 128 128 128s128-57.3 128-128-57.3-128-128-128c-13.5 0-26.5 2.1-38.7 6L375.4 48.8c-5.6-10.4-16.4-16.8-28.2-16.8h-35.2zm146.6 271.7l32.3 59.7c6.3 11.7 20.9 16 32.5 9.7s16-20.9 9.7-32.5l-32.3-59.7c3.6-.6 7.4-.9 11.2-.9 39.8 0 72 32.2 72 72s-32.2 72-72 72-72-32.2-72-72c0-18.6 7-35.5 18.6-48.3zm-325.4 64h65c-7.3 32.1-36 56-70.2 56-39.8 0-72-32.2-72-72s32.2-72 72-72c1.7 0 3.4.1 5.1.2l-24.2 48.5c-9 18.1 4.1 39.4 24.3 39.4zm33.7-48l50.7-101.3 72.9 101.2-.1 .1-123.5 0zm90.6-128h108.5l-94.1 82.8-59.6-82.8z"
        data-name="bicycle-path"
      />
    </svg>
  );
}

export default IconBicycle;
