import IconAction from './IconAction';
import IconHours from './IconHours';
import IconOffers from './IconOffers';
import IconInsurance from './IconInsurance';
import IconSlideLeft from './IconSlideLeft';
import IconSmallCCS from './IconSmallCCS';
import IconCCSLogo from './IconCCSLogo';
import IconAttachment from './IconAttachment';
import IconCloud from './IconCloud';
import IconCloudTick from './IconCloudTick';
import IconCloudy from './IconCloudy';
import IconEdit from './IconEdit';
import IconFile from './IconFile';
import IconMail from './IconMail';
import IconEmptyDoc from './IconEmptyDoc';
import IconSend from './IconSend';
import IconDoccomplete from './IconDoccomplete';
import IconTimer from './IconTimer';
import IconWarning from './IconWarning';
import IconAdd from './IconAdd';
import IconPassport from './IconPassport';
import IconUpload2 from './IconUpload2';
import IconInfo from './IconInfo';
import IconUparrow from './IconUparrow';
import IconLeftarrow from './IconLeftarrow';
import IconChevronDown from './IconChevronDown';
import IconChevronRight from './IconChevronRight';
import IconPlus from './IconPlus';
import IconArrowRight from './IconArrowRight';
import IconHide from './IconHide';
import IconShow from './IconShow';
import IconRoundTick from './IconRoundTick';
import IconLargePlus from './IconLargePlus';
import IconRemove from './IconRemove';
import IconInvoice from './IconInvoice';
import IconContact from './IconContact';
import IconChat from './IconChat';
import IconDocument from './IconDocument';
import IconHouse from './IconHouse';
import IconInsuranceSolid from './IconInsuranceSolid';
import IconCar from './IconCar';
import IconPerson from './IconPersonLuggage';
import IconInsuranceCar from './IconInsuranceCar';
import IconInsuranceCar1 from './IconInsuranceCar1';
import IconInsuranceCar2 from './IconInsuranceCar2';
import IconInsuranceCar3 from './IconInsuranceCar3';
import { Box, Typography } from '@mui/material';
import IconMore from './IconMore';
import IconSolidClock from './IconSolidClock';
import IconCalender from './IconCalendar';
import IconCalendarApproved from './IconCalendarApproved';

export const Icons = () => {
  return (
    <Box sx={{ px: 4, py: 5, background: '#f2f5ff' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', mb: 5 }}>
        <Box sx={{ mb: 2 }}>
          <Typography component={'span'} variant='overline'>
            Main Menu
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '600px' }}>
          <IconAction />
          <IconHours />
          <IconOffers />
          <IconInsurance />
          <IconSlideLeft />
          <IconCCSLogo />
          <IconSmallCCS />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', mb: 5 }}>
        <Box sx={{ mb: 2 }}>
          <Typography component={'span'} variant='overline'>
            General
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '600px', mb: 2 }}>
          <IconAttachment />
          <IconCloud></IconCloud>
          <IconCloudTick></IconCloudTick>
          <IconCloudy></IconCloudy>
          <IconEdit></IconEdit>
          <IconFile></IconFile>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '600px', mb: 2 }}>
          <IconMail></IconMail>
          <IconEmptyDoc></IconEmptyDoc>
          <IconSend></IconSend>
          <IconDoccomplete></IconDoccomplete>
          <IconTimer></IconTimer>
          <IconWarning></IconWarning>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '600px', mb: 2 }}>
          <IconMore></IconMore>
          <IconSolidClock></IconSolidClock>
          <IconCalender></IconCalender>
          <IconCalendarApproved></IconCalendarApproved>
          <IconAdd></IconAdd>
          <IconPassport></IconPassport>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '600px', mb: 2 }}>
          <IconUparrow></IconUparrow>
          <IconLeftarrow></IconLeftarrow>
          <IconChevronDown></IconChevronDown>
          <IconUpload2></IconUpload2>
          <IconInfo></IconInfo>
          <IconChevronRight></IconChevronRight>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '600px', mb: 2 }}>
          <IconArrowRight></IconArrowRight>
          <IconPlus></IconPlus>
          <IconHide></IconHide>
          <IconShow></IconShow>
          <IconRoundTick></IconRoundTick>
          <IconLargePlus></IconLargePlus>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '600px', mb: 2 }}>
          <IconRemove></IconRemove>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}>
        <Box sx={{ mb: 2 }}>
          <Typography component={'span'} variant='overline'>
            Actions
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '600px' }}>
          <IconInvoice></IconInvoice>
          <IconContact></IconContact>
          <IconChat></IconChat>
          <IconDocument></IconDocument>
          <IconHouse></IconHouse>
          <IconInsuranceSolid></IconInsuranceSolid>
          <IconCar></IconCar>
          <IconPerson></IconPerson>
          <IconInsuranceCar></IconInsuranceCar>
          <IconInsuranceCar1></IconInsuranceCar1>
          <IconInsuranceCar2></IconInsuranceCar2>
          <IconInsuranceCar3></IconInsuranceCar3>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}>
        <Box sx={{ mb: 2 }}>
          <Typography component={'span'} variant='overline'>
            Products
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '300px' }}>
          <IconInsuranceCar></IconInsuranceCar>
          <IconInsuranceCar1></IconInsuranceCar1>
          <IconInsuranceCar2></IconInsuranceCar2>
          <IconInsuranceCar3></IconInsuranceCar3>
        </Box>
      </Box>
    </Box>
  );
};
