import Server from 'entities/RCIPServerProxy';

//==============================================================
// private variables
//==============================================================

const config = {
  responseType: 'blob'
};

//==============================================================
// class FileService
//==============================================================

export class FileService {
  get(fileName: string): Promise<any> {
    return Server.get(`file/${fileName}`, config).then((data: any) => {
      return data;
    });
  }

  getList(): Promise<any> {
    return Server.get(`file/`).then((data: any) => {
      console.log(data);
      return data;
    });
  }

  delete(fileName: string): Promise<any> {
    return Server.delete(`file/${fileName}`).then((data: any) => {
      return data;
    });
  }

  save(formData: FormData): Promise<any> {
    return Server.post(`file/`, formData).then((data: any) => {
      return data;
    });
  }
}
