import React from 'react';
import { Box, Card, Typography, IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CircleIcon from '@mui/icons-material/Circle';
import { IThemeConfig } from '@ccs-dip/common/types/app-config';
import { cardStyles, cardContainerStyle } from './SettingsThemeStyles';
import { useTranslation } from 'react-i18next';


interface ThemeCardProps {
  config: IThemeConfig;
  selectedTheme?: string;
  onSelect: (config: IThemeConfig) => void;
  onEdit: (e:any) => void;
  onDelete: (e:any) => void;
}

export const ThemeCard: React.FC<ThemeCardProps> = ({
  config,
  selectedTheme,
  onSelect,
  onEdit,
  onDelete
}) => {
  const {t}=useTranslation();
 
  return (
    <Box sx={cardContainerStyle}>
      <Card
        onClick={() => onSelect(config)}
        sx={cardStyles(selectedTheme||'', config.key)}
        variant='outlined'>
        <Tooltip title={t("logo")}>
        <img src={config?.logoSmall} alt={config?.title} style={{ width: '50px', height: '50px' }} />
        </Tooltip>
        <Tooltip title={t("title")}>
        <Typography sx={{ p: 1 }} variant='subtitle1'>
          {config?.title}
        </Typography>
        </Tooltip>
        <Tooltip title={t("colors")}>
        <Box>
          <CircleIcon sx={{ color: config.theme.palette.primary.main, fontSize: '12px' }} />
          <CircleIcon sx={{ fontSize: '12px', color: config.theme.palette.secondary.main }} />
        </Box>
        </Tooltip>
        <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 1, gap: 2 }}>
        {!config?.isDefault?
          <>
          <Tooltip title={t('edit')}>
          <IconButton
            sx={{ border: 0.5, borderColor: (theme) => theme.palette.secondary.main }}
            onClick={onEdit}
            size='small'
            color='primary'>
            <EditIcon sx={{ color: (theme) => theme.palette.secondary.main, fontSize: 16 }} />
          </IconButton>
          </Tooltip>
          <Tooltip title={t('delete')}>
          <IconButton
            sx={{ border: 0.5, borderColor: (theme) => theme.palette.secondary.main }}
            onClick={onDelete}
            size='small'
            color='primary'>
            <DeleteIcon sx={{ color: (theme) => theme.palette.secondary.main, fontSize: 16 }} />
          </IconButton>
          </Tooltip>
          </>:''}
        </Box>
      </Card>
    </Box>
  );
};
