import { useTheme } from '@mui/material/styles';
function IconCar(_props: any) {
  const theme = useTheme();

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={_props.width ? _props.width : '18'}
      height={_props.height ? _props.height : '18'}
      viewBox='0 0 20 20'>
      <defs>
        <linearGradient id='duotoneGradient' x1='0%' y1='0%' x2='100%' y2='100%'>
          <stop offset='0%' style={{ stopColor: theme.palette.primary.main, stopOpacity: 1 }} />
          <stop offset='100%' style={{ stopColor: theme.palette.secondary.main, stopOpacity: 1 }} />
        </linearGradient>
      </defs>
      <path fill='none' d='M0 0h20v20H0z' data-name='Path 3658'></path>
      <path
        fill='url(#duotoneGradient)'
        d='M15.767 5.009a1.245 1.245 0 00-1.184-.842H5.417a1.253 1.253 0 00-1.183.842L2.5 10v6.667a.836.836 0 00.833.833h.833A.836.836 0 005 16.667v-.833h10v.833a.836.836 0 00.833.833h.833a.836.836 0 00.834-.833V10zm-10.059.825h8.575l.9 2.592H4.808zm10.125 8.333H4.167V10h11.666z'
        data-name='Path 3659'></path>
      <circle
        cx='1.25'
        cy='1.25'
        r='1.25'
        fill='url(#duotoneGradient)'
        data-name='Ellipse 488'
        transform='translate(5 10.833)'></circle>
      <circle
        cx='1.25'
        cy='1.25'
        r='1.25'
        fill='url(#duotoneGradient)'
        data-name='Ellipse 489'
        transform='translate(12.5 10.833)'></circle>
    </svg>
  );
}

export default IconCar;
