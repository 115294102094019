import { useTheme } from '@mui/material/styles';
function IconInsuranceSolid(_props: any) {
    const theme = useTheme();
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={_props.width ? _props.width : "18"}
            height={_props.height ? _props.height : "18"}
            viewBox="0 0 18 18"
        >
            <path fill="none" d="M0 0h18v18H0z" data-name="Path 3661"></path>
            <defs>
        <linearGradient id='duotoneGradient' x1='0%' y1='0%' x2='100%' y2='100%'>
          <stop offset='0%' style={{ stopColor: theme.palette.primary.main, stopOpacity: 1 }} />
          <stop offset='100%' style={{ stopColor: theme.palette.secondary.main, stopOpacity: 1 }} />
        </linearGradient>
      </defs>
            <path
                fill='url(#duotoneGradient)'
                d="M9 .75l-6.75 3v4.5a9.334 9.334 0 006.75 9 9.334 9.334 0 006.75-9v-4.5zm5.25 7.5A7.855 7.855 0 019 15.697 7.855 7.855 0 013.75 8.25V4.725L9 2.393l5.25 2.332zm-8.692.443L4.5 9.75l3 3 6-6-1.057-1.065L7.5 10.628z"
                data-name="Path 3662"
            ></path>
        </svg>
    );
}

export default IconInsuranceSolid;