

import { useTheme } from '@mui/material/styles';
function IconDocument(_props: any) {
    const theme = useTheme();
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={_props.width ? _props.width : "13"}
            height={_props.height ? _props.height : "16"}
            viewBox="0 0 12.8 16"
        >
            <defs>
        <linearGradient id='duotoneGradient' x1='0%' y1='0%' x2='100%' y2='100%'>
          <stop offset='0%' style={{ stopColor: theme.palette.primary.main, stopOpacity: 1 }} />
          <stop offset='100%' style={{ stopColor: theme.palette.secondary.main, stopOpacity: 1 }} />
        </linearGradient>
      </defs>
            
            <path
                fill='url(#duotoneGradient)'
                d="M8 0H1.6A1.6 1.6 0 00.008 1.6L0 14.4A1.6 1.6 0 001.592 16H11.2a1.6 1.6 0 001.6-1.6V4.8zm3.2 14.4H1.6V1.6h5.6v4h4zm-8-3.992l1.128 1.128L5.6 10.272V13.6h1.6v-3.328l1.272 1.272L9.6 10.408 6.408 7.2z"
                data-name="Path 282"
            ></path>
        </svg>
    );
}

export default IconDocument;