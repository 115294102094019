import React from 'react';
import { Box, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IForm } from '@ccs-dip/common/types/formio-types';
import { FORMCONSTANTS, sanitizeText } from './FormBuilderUtils';
import { textFieldOutline } from 'component/pages/Settings/ThemeStyles';

export interface IFormFieldsProps {
  currentForm: IForm;
  setCurrentForm: React.Dispatch<React.SetStateAction<IForm>>;
  isEditingForm: boolean;
  isWizardEditing: boolean;
  isPreviewMode: boolean;
}

const FormFields = ({
  currentForm,
  setCurrentForm,
  isEditingForm,
  isWizardEditing,
  isPreviewMode
}: IFormFieldsProps) => {
  const { t } = useTranslation();

  if (isPreviewMode) return null;

  return (
    <Box sx={{ display: 'flex', py: 2, gap: 4 }}>
      <Box sx={{ width: '15%' }}>
        <TextField
          id='Title'
          label={t('title')}
          color='primary'
          value={currentForm.title}
          onChange={(e) => {
            const updatedForm = {
              ...currentForm,
              title: e.target.value
            };
            if (!(isEditingForm || isWizardEditing)) {
              if (sanitizeText(currentForm.title) === updatedForm.name) {
                updatedForm.name = sanitizeText(updatedForm.title);
                updatedForm.key = sanitizeText(updatedForm.title);
              }
              if (sanitizeText(currentForm.title) === updatedForm.path.replace(FORMCONSTANTS.FORM_PATH_PREFIX, '')) {
                updatedForm.path = `${FORMCONSTANTS.FORM_PATH_PREFIX}${sanitizeText(updatedForm.title)}`;
              }
            }
            setCurrentForm(updatedForm);
          }}
          variant='outlined'
          fullWidth
          sx={textFieldOutline}
        />
      </Box>
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'row' }}>
        <TextField
          id='Name'
          label={t('name')}
          sx={{...textFieldOutline,width:'300px'}}
          value={currentForm.name}
          color='primary'
          variant='outlined'
          disabled={isEditingForm || isWizardEditing}
          onChange={(e) => {
            const newName = e.target.value;
            setCurrentForm({
              ...currentForm,
              name: newName,
              path: `${FORMCONSTANTS.FORM_PATH_PREFIX}${sanitizeText(newName)}`,
              key: sanitizeText(newName)
            });
          }}
          error={currentForm.name !== '' && !/^\w*[\w.-]*\w$/.test(currentForm.name)}
        />
        <TextField
          id='URL'
          label='URL'
          color='primary'
          sx={{ width: '300px', flexGrow: 1, ml: 1,...textFieldOutline }}
          value={currentForm.path}
          variant='outlined'
          disabled={isEditingForm || isWizardEditing}
        />
        <TextField
          id='ProductId'
          color='primary'
          label={t('productId')}
          sx={{ width: '300px', ml: 1,...textFieldOutline}}
          value={currentForm.productId ?? currentForm.name}
          onChange={(e) => {
            const updatedForm = {
              ...currentForm,
              productId: e.target.value
            };
            setCurrentForm(updatedForm);
          }}
          error={currentForm.productId !== '' && !/^\w*[\w.-]*\w$/.test(currentForm.productId)}
        />
      </Box>
      <Box sx={{ display: 'none' }}>
        <FormControl fullWidth>
          <InputLabel id='Display'>{t('display')}</InputLabel>
          <Select
            labelId='Display'
            id='Display'
            value={currentForm.display}
            sx={{ width: '120px' }}
            label={t('display')}
            onChange={(e) => {
              setCurrentForm({ ...currentForm, display: e.target.value, type: e.target.value });
            }}>
            <MenuItem value={FORMCONSTANTS.FORM_DISPLAY_FORM}>form</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

export default FormFields;
