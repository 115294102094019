import { formioEventHandlerType } from './attachEventHandlerToFormioInstance';
import { getTags, getValueBySubPath } from './CustomPropertySupport/helperFunctions';

//==============================================================
// private variables
//==============================================================

const getAnswerObject = (prefix: string, questionId: string, answer_text: string) => {
  const questionAnswerObject = {
    QuestionAnswer: {
      QuestionId: questionId,
      Text: answer_text
    }
  };
  return prefix
    .split('.')
    .reverse()
    .reduce<object>((prev, key) => ({ [key]: prev }), questionAnswerObject);
};

// Removes empty valued objects
const getGridValueFormInstance = (instance: any) => {
  const value: any[] = instance.getValue() ?? [];
  return value.filter((current) => getValueBySubPath(current, 'QuestionId') !== '');
};

const onSetSubmission = (data: any, gridValue: any[], answer_record: Record<string, number>) => {
  gridValue.forEach((answerObject, index) => {
    const questionId = getValueBySubPath(answerObject, 'QuestionId') as string;
    const answer_text = getValueBySubPath(answerObject, 'Text') as string;
    answer_record[questionId] = index;
    data[questionId] = answer_text;
  });
  data.answer_record = answer_record;
};

const onUserInput = (
  instance: any,
  questionId: string,
  answer_text: string,
  gridValue: any[],
  answer_record: Record<string, number>
) => {
  const array_path: string = instance.component.properties.array_path;
  const prefix_key = array_path.split('.QuestionAnswer')[0] ?? '';

  const index = answer_record[questionId] ?? gridValue.length;
  gridValue[index] = getAnswerObject(prefix_key, questionId, answer_text);
  answer_record[questionId] = index;

  // setting the value
  instance.setValue(gridValue);
  instance.root.submission.data.answer_record = answer_record;

  // The instance needs to be redrawn if it needs to be shown in the UI
  // instance.redraw();
};

//==============================================================
// public variables
//==============================================================

export const acceptanceChangeEventHandler: formioEventHandlerType = (eventData, instance, _emitEventName) => {
  const changedInstance = eventData.changed?.instance;
  const gridValue = getGridValueFormInstance(instance);
  // to store the index of particular questionId, which is used for setting the value
  const answer_record: Record<string, number> = eventData.data?.answer_record ?? {};

  if (Object.keys(answer_record).length !== gridValue.length) {
    onSetSubmission(eventData.data, gridValue, answer_record);
  } else if (changedInstance && getTags(changedInstance.parent.component).includes('QuestionAnswer')) {
    const questionId: string = changedInstance.key;
    const answer_text: string = changedInstance.getValue();
    onUserInput(instance, questionId, answer_text, gridValue, answer_record);
  }
};
