export const dropDownStyle = {
  '&.MuiOutlinedInput-root': { marginTop: 0 },
  backgroundColor: '#fff',
  '& .selectStyle': {
    '& .MuiSelect-select.MuiInputBase-input': {
      color: (theme:any)=>theme.palette.background.paper
    }
  },
  '& .selectInputPropsStyle': {
    color: (theme:any)=>theme.palette.primary.main
  }
};
