import { JSONItem } from '@ccs-dip/common/json/types';
import { CustomPropertyName } from '@ccs-dip/common/types/formio-types';
import { Utils } from 'formiojs';

export const getFieldValue = (instance: any, value: string) => {
  const page_key = getCustomProperty(instance.component, 'page_key');
  return `${page_key !== '' ? page_key : instance.root.form.name}-${value}`;
};

export const searchComponents = (instance: any, conditon: (instance: any) => boolean) => {
  const result: any[] = [];
  const helper = (current_instance: any) => {
    if (current_instance.components) {
      // nested components
      current_instance.components.forEach((nestedComponentInstance: any) => helper(nestedComponentInstance));
    } else if (current_instance.subForm) {
      // nested form component
      helper(current_instance.subForm);
    }
    if (conditon(current_instance)) {
      result.push(current_instance);
    }
  };
  helper(instance);
  return result;
};

export const getNewInstance = (instance: any) => {
  const condition = (current_instance: any) => instance.key === current_instance.key;
  return searchComponents(instance.root, condition)[0] ?? instance;
};

export const redrawAndRestore = (instance: any) => {
  instance.redraw();
  instance.restoreComponentsContext();
};

export const getCustomProperty = (component: any, property: CustomPropertyName) => {
  return (component.properties[property] ?? '') as string;
};

export const getTags = (component: any): string[] => {
  if (component) {
    return component.tags ?? [];
  }
  return [];
};

export const getGridValueFormInstance = (instance: any) => {
  return (instance.getValue() ?? []).filter((current: any) => Utils.getValue({ data: current }, 'ExtCoverageTypeCd'));
};

export const getValueBySubPath = (object: object, path: string): JSONItem => {
  const keys = path.split('.');

  const getValueUsing = (obj: object) => {
    return keys.reduce<unknown>(
      (prev, key) => (prev && typeof prev === 'object' ? (prev as Record<string, unknown>)[key] : undefined),
      obj
    ) as JSONItem | undefined;
  };

  const helper = (obj: object): JSONItem | undefined => {
    const o = obj as Record<string, unknown>;

    for (const key of Object.keys(o)) {
      if (key === keys[0]) {
        const value = getValueUsing(o);
        if (value !== undefined) {
          return value;
        }
      } else {
        const value = o[key];
        if (value && typeof value === 'object') {
          const result = helper(value);
          if (result !== undefined) {
            return result;
          }
        }
      }
    }

    return undefined;
  };

  return helper(object) ?? '';
};
