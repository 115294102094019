import { useTheme } from '@mui/material/styles';
function IconInvoice(_props: any) {
  const theme = useTheme();
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={_props.width ? _props.width : '16'}
      height={_props.height ? _props.height : '16'}
      viewBox='0 0 16 16.014'>
      <path fill='none' d='M0 0h16v16H0z' data-name='Path 237'></path>
      <defs>
        <linearGradient id='duotoneGradient' x1='0%' y1='0%' x2='100%' y2='100%'>
          <stop offset='0%' style={{ stopColor: theme.palette.primary.main, stopOpacity: 1 }} />
          <stop offset='100%' style={{ stopColor: theme.palette.secondary.main, stopOpacity: 1 }} />
        </linearGradient>
      </defs>
      <path
        fill='url(#duotoneGradient)'
        d='M4.805 11.214h6.389v1.6H4.805zm0-3.2h6.389v1.6H4.805zm4.792-8H3.211a1.6 1.6 0 00-1.6 1.6v12.8a1.6 1.6 0 001.589 1.6h9.591a1.6 1.6 0 001.6-1.6v-9.6zm3.194 14.4h-9.58v-12.8h5.59v4h3.99z'
        data-name='Path 238'></path>
    </svg>
  );
}

export default IconInvoice;
