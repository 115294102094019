import { AlertColor } from '@mui/material';
import { i18n } from 'i18next';

//===============================================
// types
//===============================================

type OnCustomEventFunction = (
  eventData: any,
  showSnackBar: (text: string, typeColor: AlertColor) => void,
  i18n: i18n
) => void;

//===============================================
// public variables
//===============================================

export const onCustomEvent: OnCustomEventFunction = (eventData, showSnackBar, i18n) => {
  const type: string = eventData.type ?? '';
  switch (type) {
    case 'success': {
      const operation: string = eventData.component.properties.operation ?? '';
      showSnackBar(i18n.t(`success.${operation}`), 'success');
      break;
    }
    case 'error': {
      // TODO: Find a better alternative
      setTimeout(() => {
        const instance = eventData.instance;
        instance.checkValidity(null, true, null, false);
      }, 500);
      break;
    }
    default:
      console.log('Unsupported Type');
  }
};
