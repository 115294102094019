export const emailTypeFeildStyle = {
  fontSize: 14,
  pr: 1,
  height: 43,
  '& .MuiInputBase-root': { pr: 0 },
  'label+.MuiOutlinedInput-root': { mt: 0 },
  '& .selectStyle': {
    backgroundColor: (theme:any)=>theme.palette.background.paper,
    boxShadow: 'none',
    mr: 0,
    '& .MuiOutlinedInput-input': { border: 0 },
    '& .MuiOutlinedInput-notchedOutline': { border: 0 }
  },
  '.MuiInputBase-root .MuiOutlinedInput-root .MuiInputBase-input': { border: 'none' },
  '.MuiFormControl-root .MuiInputBase-root.MuiOutlinedInput-root': { backgroundColor: (theme:any)=>theme.palette.background.paper},
  '.MuiFormControl-root': { pr: '3px' },
  '.MuiFormControl-root .MuiOutlinedInput-notchedOutline': { border: 'none', borderColor: '#fff' }
};
export const menuItemStyle={
  backgroundColor: (theme:any) => theme.palette.background.paper, 
  '&.Mui-selected': {
    backgroundColor: (theme:any) => theme.palette.secondary.light,
  }
}