import { Components } from 'formiojs';
import { IComponent, IContainer } from '@ccs-dip/common/types/formio-types';
import { Iterable, iterate } from '@ccs-dip/common/iterate';

//===============================================
// interfaces
//===============================================

interface FormioComponent extends Function {
  editForm?: () => { components: IComponent[] };
}

//===============================================
// private methods
//===============================================

const requiresFieldBinding = (name: string) => {
  const black_list = [
    // data
    'container',
    'datagrid',
    'editgrid',
    'hidden',
    // layout
    'htmlelement',
    'content',
    'columns',
    'fieldset',
    'panel',
    'table',
    'tabs',
    'well',
    // basic
    'button'
  ];
  return !black_list.includes(name);
};

const keyComponentValidate = {
  required: false,
  minLength: 0,
  maxLength: 500
};

export const fieldSelectComponent: IComponent = {
  weight: 0,
  type: 'textfield',
  input: true,
  key: 'fieldSelector',
  label: 'Select Property name',
  validate: {
    required: true,
    minLength: 0,
    maxLength: 500
  },
  autocomplete: 'off',
  attributes: {
    id: 'fieldSelector'
  },
  tooltip: 'This is used for setting up the Property Name',
  customDefaultValue: 'formio_support.apiFieldCustomDefaultValue()'
};

export const showInSummaryCheckBoxComponent: IComponent = {
  weight: 1700,
  type: 'checkbox',
  label: 'Show in Summary',
  tooltip: 'Allows to show the value of this component, in summary.',
  key: 'showInSummary',
  validate: {
    maxLength: '',
    minLength: ''
  },
  input: true
};

const getComponentsFromChild = (name: string, components?: IComponent[]) => {
  return components?.find((component) => component.key === name)?.components;
};

const extendNestedFormComponent = (tabs: IComponent[]) => {
  const components = getComponentsFromChild('form', tabs);
  if (components) {
    const form_component = components.find((component) => component.key === 'form');
    if (form_component && form_component.data) {
      form_component.data.url = '/';
      form_component.valueProperty = 'key';
    }
  }
};

const extendApiTab = (tabs: IComponent[]) => {
  const components = getComponentsFromChild('api', tabs);
  if (components) {
    const key_component = components.find((component) => component.key === 'key');
    if (key_component) {
      key_component.disabled = true;
      key_component.validate = keyComponentValidate;
      components.unshift(fieldSelectComponent);
    }
  }
};

const extendDisplayTab = (tabs: IComponent[]) => {
  const components = getComponentsFromChild('display', tabs);
  if (components) {
    components.push(showInSummaryCheckBoxComponent);
  }
};

const extendAnyComponent = (tabs: IComponent[]) => {
  extendApiTab(tabs);
  extendDisplayTab(tabs);
};

const extendEditForm = (editForm: IContainer, name: string): void => {
  const tabs = getComponentsFromChild('tabs', editForm.components);
  if (tabs) {
    switch (name) {
      case 'form':
        extendNestedFormComponent(tabs);
        break;
      default:
        extendAnyComponent(tabs);
    }
  }
};

//===============================================
// public methods
//===============================================

export const replaceEditFormFunctions = () => {
  const components = Components.components as Iterable<FormioComponent>;

  iterate(components, (name, component) => {
    if (requiresFieldBinding(name) && component.editForm) {
      const original = component.editForm;

      // set new editForm factory function
      component.editForm = () => {
        const editForm = original();
        extendEditForm(editForm, name);
        return editForm;
      };
    }
  });
};
