export const isJsonValue = (item) => {
    return typeof item !== 'object';
};
export const isJsonObject = (item) => {
    return item !== null && typeof item === 'object' && !Array.isArray(item);
};
export const isJsonArray = (item) => {
    return typeof item === 'object' && Array.isArray(item);
};
export const isJsonValuedArray = (item) => {
    return typeof item === 'object' && Array.isArray(item) && item.every((value) => typeof value !== 'object');
};
export const updateJsonObjectBykey = (object, target, newVal) => {
    if (isJsonArray(object)) {
        for (let i = 0; i < object.length; i++) {
            if (object[i] !== undefined && typeof object[i] === 'object') {
                updateJsonObjectBykey(object[i], target, newVal);
            }
        }
        return;
    }
    for (const key in object) {
        if (key === target) {
            object[key] = newVal;
        }
        else if (object[key] !== undefined && typeof object[key] === 'object') {
            updateJsonObjectBykey(object[key], target, newVal);
        }
    }
};
export const logJson = (object) => {
    const json = JSON.stringify(object, null, 2);
    console.log(`${json}\n`);
};
