export const cardCover=
{ 
    width: '400px', 
    textAlign: 'center', 
    padding: '20px', 
    boxShadow: 3 
  }

  export const overlay={
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2147483647, // Ensure it's on top
}

export const chromeWindow={
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  p: 4,
  flexDirection: 'column',
  border: '1px solid #ccc',
  borderRadius: '8px',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  backgroundColor: 'white',
  width: '400px',
  position: 'relative',
}

export const chromeHeader={
  position: 'absolute',
  top: '0',
  left: '0',
  right: '0',
  height: '30px',
  backgroundColor: '#f1f1f1',
  borderTopLeftRadius: '8px',
  borderTopRightRadius: '8px',
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '10px',
  fontWeight: 'bold',
  color: '#999',
}

export const messageWindow={ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', textAlign: 'center' };
export const description={ color: '#ccc', fontSize: 14 };
export const iconCover={ fontSize: 60, color: 'red', mb: 2 }