import { Form } from 'formiojs';
import { IForm } from '@ccs-dip/common/types/formio-types';
import FormioService from 'entities/Formio/FormioService';
import { formioEventHandlerType } from '../attachEventHandlerToFormioInstance';
import { getCustomProperty, getFieldValue, getValueBySubPath, searchComponents } from './helperFunctions';

//===============================================
// private variables
//===============================================
const formio_service = new FormioService();

const createWizardInstance = (instance: any) => {
  const wizardInstance = new Form().create('wizard');
  // copying over the previous instance object's values.
  Object.keys(instance).forEach((key) => {
    wizardInstance[key] = instance[key];
  });
  return wizardInstance;
};

const replaceFormByForm = (instance: any, form: IForm) => {
  if (form.display === 'wizard') {
    instance.subForm = createWizardInstance(instance.subForm);
  }
  instance.subForm.form = form;
  const value = instance.getValue();
  value.metadata = { key: form.key };
  instance.setValue(value);
  if (form.display === 'wizard') {
    // to trigger custom default value evaluation for container components
    const condition = (component: any) =>
      component.type === 'container' && component.getCustomDefaultValue('') !== undefined;
    searchComponents(instance.subForm, condition);
  }
};

const replaceFormByKey = (instance: any, key: string) => {
  if (key === '') {
    replaceFormByForm(instance, {} as IForm);
  } else if (instance.subForm.form.key !== key) {
    formio_service
      .get(key)
      .then((form) => replaceFormByForm(instance, form))
      .catch((_reason) => {});
  }
};

const replaceFormByValue = (instance: any, value: string) => {
  const prevKey = `prev-${instance.key}`;
  const nestedFormValue = instance.getValue();
  if (value === '') {
    nestedFormValue.data[prevKey] = '';
    replaceFormByForm(instance, {} as IForm);
    return;
  }

  const subForm = instance.subForm;
  const isReplaced =
    nestedFormValue.data[prevKey] !== value || (subForm && subForm.form.key !== nestedFormValue?.metadata?.key);

  if (subForm && isReplaced) {
    nestedFormValue.data[prevKey] = value;
    formio_service
      .getKey(getFieldValue(instance, value))
      .then((key) => replaceFormByKey(instance, key ?? ''))
      .catch((_reason) => {});
  }
};

//==============================================================
// public variables
//==============================================================

export const replaceFormChangeEventHandler: formioEventHandlerType = (eventData, instance, _) => {
  const fieldName = getCustomProperty(instance.component, 'replace_form');
  const changedInstance = eventData.changed?.instance;
  const fieldValue = getValueBySubPath(eventData.data, fieldName);

  const isValueEmpty = typeof fieldValue !== 'string' || fieldValue === '';
  const isSameInstance = changedInstance && changedInstance.key === fieldName;

  if (!isValueEmpty || isSameInstance) {
    replaceFormByValue(instance, isValueEmpty ? eventData.changed.value : fieldValue);
  }
};
