import { AlertColor } from '@mui/material';
import { whiteList } from './UrlWhiteList';

export const createErrorFetchPlugin = (showSnackBar: (text: string, typeColor: AlertColor) => void) => ({
  priority: 0,
  wrapStaticRequestPromise: (promise: any, _requestArgs: any) => {
    const urliswhiteListed=whiteList.find((itm:any)=> (new RegExp(itm,'ig')).test(_requestArgs.url));
    return promise.catch((error: any) => {
      const message = typeof error.error === 'string' ? error.error : error.error?.message ?? 'error in formio';
      if(!urliswhiteListed)
      showSnackBar(message, 'error');
      throw error;
    });
  }
});
