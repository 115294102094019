import { loaderService } from "entities/Loader/LoaderService";

export const createFormioLoaderPlugin = () => {
  return {
    // Triggered before any API request
    wrapStaticRequestPromise:(_promise:any)=> {
      // Show the loader when a request starts
      return loaderService.loader(_promise); // Ensures the loader is visible
    }
  };
};

