import { styled, Theme } from '@mui/material';

export const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
  bgcolor: (theme: any) => theme.palette.secondary.main
});

export const uploadButton = {
  bgcolor: (theme: Theme) => theme.palette.secondary.main,
  mr: 2,
  fontSize: '12px',
  cursor: 'pointer',
  minWidth: 'auto',  
  padding: '6px 16px', 
  width: 'auto',  
  textWrap:'nowrap'
};

export const formButtonWrap = {
  bgcolor: (theme: Theme) => theme.palette.secondary.main,
  mr: 2,
  fontSize: '12px',
  cursor: 'pointer',
  minWidth: 'auto',  
  padding: '6px 16px', 
  width: 'auto',  
  textWrap:'nowrap !important'
};

export const toolBar = { display: 'flex', justifyContent: 'flex-end', gap: '20px'};

export const toolbarWrap = { width: '400px', display: 'inline-flex', justifyContent: 'flex-end' };

export const iconWrapperDisabled = { color: () => '#ccc' };
export const iconWrapper = { color: (theme: Theme) => theme.palette.secondary.main };

export const launchButton = {
  borderRadius: '30px',
  fontSize: '11px',
  bgcolor: (theme: Theme) => theme.palette.secondary.main,
  cursor: 'pointer'
};

export const titleCell = {
  pr: 1,
  fontSize: '28px',
  color: (theme: Theme) => theme.palette.secondary.main
};

export const titleText = {
  whiteSpace: 'nowrap',
  width: '175px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  cursor: 'pointer'
};

export const labelIcon = { pr: 1, fontSize: '28px', color: (theme: Theme) => theme.palette.secondary.main };
export const descriptionIcon = { pr: 1, fontSize: '28px', color: (theme: Theme) => theme.palette.secondary.main };
export const displayForm = { textTransform: 'capitalize', display: 'inline', fontSize: '14px' };
export const addButton = { fontSize: '20px', mr: 1 };
