import { useTheme } from '@mui/material/styles';
function IconChat(_props: any) {
    const theme = useTheme();
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={_props.width ? _props.width : "16"}
            height={_props.height ? _props.height : "16"}
            viewBox="0 0 16 16"
        >
            <defs>
        <linearGradient id='duotoneGradient' x1='0%' y1='0%' x2='100%' y2='100%'>
          <stop offset='0%' style={{ stopColor: theme.palette.primary.main, stopOpacity: 1 }} />
          <stop offset='100%' style={{ stopColor: theme.palette.secondary.main, stopOpacity: 1 }} />
        </linearGradient>
      </defs>
            <path
                fill='url(#duotoneGradient)'
                d="M10.4 1.6v5.6H2.536l-.472.472-.464.464V1.6h8.8m.8-1.6H.8a.8.8 0 00-.8.8V12l3.2-3.2h8A.8.8 0 0012 8V.8a.8.8 0 00-.8-.8zm4 3.2h-1.6v7.2H3.2V12a.8.8 0 00.8.8h8.8L16 16V4a.8.8 0 00-.8-.8z"
                data-name="Path 3533"
            ></path>
        </svg>
    );
}

export default IconChat;
