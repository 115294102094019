import React from 'react';
import { Grid, Card, CardContent, Typography, IconButton, Box, Button } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ITileInfo } from '@ccs-dip/common/types/app-config';
import { OverviewLink, styles } from './InsuranceOverviewStyles';
import { useTranslation } from 'react-i18next';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { generateOverviewUrl, getOverviewIconData } from 'utils/Utils';

interface InsuranceOverviewGridProps {
  filteredOverviewList: ITileInfo[];
  handleMenuOpen: (event: React.MouseEvent<HTMLButtonElement>, index: number) => void;
  handleAddClick: () => void;
  partynumber?: string;
}

const InsuranceOverviewGrid: React.FC<InsuranceOverviewGridProps> = ({
  filteredOverviewList,
  handleMenuOpen,
  handleAddClick,
  partynumber
}) => {
  const { t } = useTranslation();

  return (
    <Box sx={styles.contentBox}>
      <Grid
        container
        spacing={2}
        sx={{ alignItems: 'stretch' }} 
      >
        {filteredOverviewList.map((overviewItem, index) => (
          <Grid item xs={12} sm={6} md={4} key={index} sx={{ minWidth: '300px', position: 'relative', zIndex: 2 }}>
            <Card sx={{...styles.card, ...styles.cardWrapper}}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <IconButton onClick={(e) => handleMenuOpen(e, index)}>
                  <MoreVertIcon />
                </IconButton>
              </Box>
              <OverviewLink to={generateOverviewUrl(overviewItem.url, partynumber)}>
                <Box sx={styles.iconBox}>
                  {overviewItem.icon ? getOverviewIconData(overviewItem.icon)?.icon : ''}
                  <Typography
                    sx={{
                      ...styles.title,
                      ...styles.truncate
                    }}
                  >
                    {t(overviewItem.title)}
                  </Typography>
                </Box>
                <CardContent>
                  <Typography
                    variant='body2'
                    color='text.secondary'
                    sx={styles.truncate}
                  >
                    {t(overviewItem.description)}
                  </Typography>
                </CardContent>
              </OverviewLink>
            </Card>
          </Grid>
        ))}
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          onClick={handleAddClick}
          sx={{ minWidth: '300px', position: 'relative', zIndex: 10000 }}
        >
          <Box display='flex' justifyContent='center' alignItems='center' height='100%' sx={{ position: 'relative', zIndex: 10000 }}>
            <Button variant='outlined' color='secondary' sx={styles.addBoxWrapper}>
              <AddBoxIcon sx={{ fontSize: '30px', mr: 1 }} />
              <Typography sx={{ color: (theme: any) => theme.palette.text.primary }}>{t('add')}</Typography>
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InsuranceOverviewGrid;
