// Constants.ts

export const REPLACEMENTS = {
  STREET_NAME: 'StreetName',
  SURNAME: 'Surname',
  ACCESSORIES: 'Accessories',
  RelationShip: 'Relationship',
  RelationShipToCd: 'RelationshipToCd'
};

export const DEFAULT_COUNTRY_CODE = 'ad';

export const FILE_READING_ERROR = 'File reading failed';
export const INVALID_JSON_ERROR = 'Invalid JSON format';
export const ERROR_READING_FILE = 'Error reading file';
export const ERROR = 'error';

export const CURRENCY_SYMBOLS: { [key: string]: string } = { EUR: '€ ' };

export const ARCHIVE_FILE_DOWNLOAD_EXTENSION = '.';

export const SNACKBAR_ERROR_MESSAGE = 'Error reading file';

// Other Constants
export const REGEX_PATTERN = /[^a-zA-Z1-9\s]/g;
export const JSON_FILE_PATTERN = /.json/i;
export const SWAGGER_FILE_PATTERN = /.json|.yml|.yaml$/i;
export const PHONE_TYPE_CD = 'PhoneTypeCd';
export const PHONE_NUMBER = 'PhoneNumber';
