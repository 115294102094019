import { useEffect, useState } from 'react';
import { Avatar, Backdrop, Box, Divider, Grid, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
//import { Link } from 'react-router-dom';
import ClaimService from 'entities/Claim/ClaimService';
import { IClaim } from 'entities/Claim/IClaim';
//import { useTheme } from '@mui/material/styles';
import { contentText } from 'utils/Utils';
import Tooltip from '@mui/material/Tooltip';
import Loader from 'component/organisms/Loader/Loader';
import { useSnackBar } from 'context/Snackbar/Snackbar';
import {
  addressDetailsText,
  claimDetailsText,
  claimDetailsWrap,
  claimNumber,
  //editText,
  lossDetailsText,
  //mordetailsText,
  partyDetailsText,
  surname,
  surnameWrapper
} from './ClaimsStyles';

interface IClaimBriefViewProps {
  claimNumber: string;
}

export const ClaimBriefView = (props: IClaimBriefViewProps) => {
  const [isloading, setisloading] = useState(true);
  //const theme = useTheme();
  const { t } = useTranslation<string>();
  const [claimDetails, setClaimDetails] = useState<IClaim>();
  const service = new ClaimService();
  const { showSnackBar } = useSnackBar();

  const getClaimDetails = () => {
    return service
      .get(props.claimNumber ?? '')
      .then((claim: any) => {
        console.log(claim);
        setClaimDetails(claim);
        setisloading(false);
      })
      .catch((error: any) => {
        showSnackBar(error.message, 'error');
        setClaimDetails(undefined);
        setisloading(false);
      });
  };

  useEffect(() => {
    setisloading(true);
    props.claimNumber && getClaimDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.claimNumber]);

  const claimInfo = {
    claimNumber: claimDetails?.ClaimsOccurrence.ClaimNumber,
    companysClaimNumber: claimDetails?.ClaimsOccurrence.CompanysClaimNumber,
    companysPolicyNumber: claimDetails?.Policy.CompanysPolicyNumber,
    claimsReportedDt: claimDetails?.ClaimsOccurrence.ClaimsReported[0]?.ReportedDt,
    claimStatusCd: claimDetails?.ClaimsOccurrence.ClaimStatusCd,
    lossDt: claimDetails?.ClaimsOccurrence.LossDt,
    lossTime: claimDetails?.ClaimsOccurrence.LossTime,
    surName: claimDetails?.ClaimsParty[1]?.Surname,
    dob: claimDetails?.ClaimsParty[1]?.BirthDt,
    emailAddress: claimDetails?.ClaimsParty[1]?.Communications?.[0]?.EmailAddr,
    postalCode: claimDetails?.ClaimsParty[1]?.Addr?.[0]?.PostalCode,
    addr1: claimDetails?.ClaimsParty[1]?.Addr?.[0]?.Addr1,
    city: claimDetails?.ClaimsParty[1]?.Addr?.[0]?.City
  };

  return (
    <>
      {isloading ? (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isloading}
          onClick={() => {}}>
          <Loader></Loader>
        </Backdrop>
      ) : (
        <Box id='ClaimBriefView' sx={{ p: 3 }}>
          {claimDetails ? (
            <>
              <Stack direction={'row'} spacing={1} alignItems='center'>
                <Avatar sx={{ bgcolor: (theme) => theme.palette.secondary.main, width: 70, height: 70 }}>
                  <Typography>CD</Typography>
                </Avatar>

                <Stack direction='column' spacing={1}>
                  {claimInfo?.surName && (
                    <>
                      <Box sx={surnameWrapper}>
                        <Tooltip title={claimInfo.surName}>
                          <Typography id='HeaderSurname' sx={surname}>
                            {claimInfo.surName}
                          </Typography>
                        </Tooltip>
                      </Box>
                    </>
                  )}

                  {claimInfo?.claimNumber && (
                    <>
                      <Typography id='HeaderCompanysClaimNumber' sx={claimNumber}>
                        #{claimInfo?.companysClaimNumber}
                      </Typography>
                    </>
                  )}
                </Stack>
              </Stack>

              <Stack spacing={1} sx={claimDetailsWrap}>
                {(claimInfo.claimNumber ||
                  claimInfo.companysClaimNumber ||
                  claimInfo.companysPolicyNumber ||
                  claimInfo.claimsReportedDt ||
                  claimInfo.claimStatusCd) && (
                  <>
                    <Typography sx={claimDetailsText}>{t('claimdetails')}</Typography>

                    <Grid container spacing={2}>
                      {claimInfo?.companysClaimNumber && (
                        <>
                          <Grid item xs={6}>
                            <Typography sx={contentText}>{t('claimNumber')}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography id='ClaimNumber' sx={contentText}>
                              {claimInfo?.companysClaimNumber}
                            </Typography>
                          </Grid>
                        </>
                      )}

                      {claimInfo?.claimNumber && (
                        <>
                          <Grid item xs={6}>
                            <Typography sx={contentText}>{t('internalClaimNumber')}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography id='InternalClaimNumber' sx={contentText}>
                              {claimInfo?.claimNumber}
                            </Typography>
                          </Grid>
                        </>
                      )}

                      {claimInfo?.companysPolicyNumber && (
                        <>
                          <Grid item xs={6}>
                            <Typography sx={contentText}>{t('policyNumber')}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography id='policyNumber' sx={contentText}>
                              {claimInfo?.companysPolicyNumber}
                            </Typography>
                          </Grid>
                        </>
                      )}

                      {claimInfo?.claimsReportedDt && (
                        <>
                          <Grid item xs={6}>
                            <Typography sx={contentText}>{t('claimsReportedDt')}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography id='ReportedDt' sx={contentText}>
                              {claimInfo?.claimsReportedDt}
                            </Typography>
                          </Grid>
                        </>
                      )}

                      {claimInfo?.claimStatusCd && (
                        <>
                          <Grid item xs={6}>
                            <Typography sx={contentText}>{t('claimStatusCd')}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography id='ClaimStatusCd' sx={contentText}>
                              {claimInfo?.claimStatusCd}
                            </Typography>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </>
                )}

                {(claimInfo.lossDt || claimInfo.lossTime) && (
                  <>
                    <Divider />
                    <Typography sx={lossDetailsText}>{t('lossDetails')}</Typography>
                    <Grid container spacing={2}>
                      {claimInfo?.lossDt && (
                        <>
                          <Grid item xs={6}>
                            <Typography sx={contentText}>{t('lossDt')}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography id='LossDt' sx={contentText}>
                              {claimInfo?.lossDt}
                            </Typography>
                          </Grid>
                        </>
                      )}

                      {claimInfo?.lossTime && (
                        <>
                          <Grid item xs={6}>
                            <Typography sx={contentText}>{t('lossTime')}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography id='LossTime' sx={contentText}>
                              {claimInfo?.lossTime}
                            </Typography>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </>
                )}

                {(claimInfo.surName || claimInfo.dob || claimInfo.emailAddress) && (
                  <>
                    <Divider />
                    <Typography sx={partyDetailsText}>{t('partyDetails')}</Typography>
                    <Grid container spacing={2}>
                      {claimInfo.surName && (
                        <>
                          <Grid item xs={6}>
                            <Typography sx={contentText}>{t('lastName')}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography id='ClaimPartySurname' sx={contentText}>
                              {claimInfo.surName}
                            </Typography>
                          </Grid>
                        </>
                      )}

                      {claimInfo.dob && (
                        <>
                          <Grid item xs={6}>
                            <Typography sx={contentText}>{t('dob')}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography id='BirthDt' sx={contentText}>
                              {claimInfo.dob}
                            </Typography>
                          </Grid>
                        </>
                      )}

                      {claimInfo.emailAddress && (
                        <>
                          <Grid item xs={6}>
                            <Typography sx={contentText}>{t('emailAddress')}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography id='EmailAddress' sx={contentText}>
                              {claimInfo.emailAddress}
                            </Typography>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </>
                )}

                {(claimInfo.postalCode || claimInfo.addr1 || claimInfo.city) && (
                  <>
                    <Divider />
                    <Typography sx={addressDetailsText}>{t('addressDetails')}</Typography>
                    <Grid container spacing={2}>
                      {claimInfo.postalCode && (
                        <>
                          <Grid item xs={6}>
                            <Typography sx={contentText}>{t('postalCode')}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography id='PostalCode' sx={contentText}>
                              {claimInfo.postalCode}
                            </Typography>
                          </Grid>
                        </>
                      )}

                      {claimInfo.addr1 && (
                        <>
                          <Grid item xs={6}>
                            <Typography sx={contentText}>{t('addr1')}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography id='Addr1' sx={contentText}>
                              {claimInfo.addr1}
                            </Typography>
                          </Grid>
                        </>
                      )}

                      {claimInfo.city && (
                        <>
                          <Grid item xs={6}>
                            <Typography sx={contentText}>{t('city')}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography id='City' sx={contentText}>
                              {claimInfo.city}
                            </Typography>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </>
                )}
              </Stack>

              {/*<Stack direction={'row'} spacing={1} justifyContent='space-between'>
                <Stack direction={'column'} spacing={1}>
                  <Typography id='MoreDetails' variant='subtitle2' display='block' sx={mordetailsText} gutterBottom>
                    <Link to={`#`} style={{ color: theme.palette.secondary.main }}>
                      {t('moreDetails')}
                    </Link>
                  </Typography>
                </Stack> 
                <Stack direction={'column'} spacing={1}>
                  <Typography id='Edit' variant='subtitle2' display='block' sx={editText} gutterBottom>
                    <Link to={`#`} style={{ color: theme.palette.secondary.main }}>
                      {t('edit')}
                    </Link>
                  </Typography>
                </Stack>
              </Stack>*/}
            </>
          ) : (
            <Typography>{t('noData')}</Typography>
          )}
        </Box>
      )}
    </>
  );
};
