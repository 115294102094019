import { Button, Grid, Typography } from '@mui/material';
import { countries } from './Constants';
import { Theme } from '@mui/material/styles';
import ArchiveService from 'entities/Archive/ArchiveService';
import { IArchive } from 'entities/Archive/IArchive';
import { ChangeEvent } from 'react';
import { IComponent } from '@ccs-dip/common/types/formio-types';
import { LanguageTranslation } from 'component/pages/Translation/ITranslationDataApi';
import IncidentIcon from 'component/atoms/Icons/IconIncident';
import IconCarside from 'component/atoms/Icons/IconCarside';
import IconGlobe from 'component/atoms/Icons/IconGlobe';
import {
  ARCHIVE_FILE_DOWNLOAD_EXTENSION,
  CURRENCY_SYMBOLS,
  DEFAULT_COUNTRY_CODE,
  ERROR,
  FILE_READING_ERROR,
  INVALID_JSON_ERROR,
  PHONE_NUMBER,
  PHONE_TYPE_CD,
  REPLACEMENTS,
  SNACKBAR_ERROR_MESSAGE
} from './UtilsConstants';
import { phoneNumberStyle } from './UtilsStyles';
import IconInvoice from 'component/atoms/Icons/IconInvoice';
import IconContact from 'component/atoms/Icons/IconContact';
import IconChat from 'component/atoms/Icons/IconChat';
import IconDocument from 'component/atoms/Icons/IconDocument';
import IconHouse from 'component/atoms/Icons/IconHouse';
import IconInsuranceSolid from 'component/atoms/Icons/IconInsuranceSolid';
import IconCar from 'component/atoms/Icons/IconCar';
import IconPerson from 'component/atoms/Icons/IconPersonLuggage';
import IconInsuranceCar from 'component/atoms/Icons/IconInsuranceCar';
import IconInsuranceCar1 from 'component/atoms/Icons/IconInsuranceCar1';
import IconInsuranceCar2 from 'component/atoms/Icons/IconInsuranceCar2';
import IconInsuranceCar3 from 'component/atoms/Icons/IconInsuranceCar3';
import IconTruck from 'component/atoms/Icons/IconTruck';
import IconBicycle from 'component/atoms/Icons/IconBicycle';
import IconVehicle from 'component/atoms/Icons/IconVehicle';
import IconSideTruck from 'component/atoms/Icons/IconSideTruck';
import IconTruckTransport from 'component/atoms/Icons/IconTruckTransport';
import IconVehicleTransport from 'component/atoms/Icons/IconVehicleTransport';
import IconCarAndDriver from 'component/atoms/Icons/IconCarAndDriver';
import IconDocumentEdit from 'component/atoms/Icons/IconDocumentEdit';
import IconPlusSquare from 'component/atoms/Icons/IconPlusSquare';
import IconMountain from 'component/atoms/Icons/IconMountain';
import IconBook from 'component/atoms/Icons/IconBook';
import IconCaravanTrail from 'component/atoms/Icons/IconCaravanTrail';
import IconBalance from 'component/atoms/Icons/IconBalance';
import IconWheelChair from 'component/atoms/Icons/IconWheelChair';
import PersonFall from 'component/atoms/Icons/IconPersonFall';
import IconTruckMedical from 'component/atoms/Icons/IconTruckMedical';
import IconCouch from 'component/atoms/Icons/IconCouch';
import IconHouseLaptop from 'component/atoms/Icons/IconHouseLaptop';
import IconHouseLight from 'component/atoms/Icons/IconHouseLight';
import IconBodyLuggage from 'component/atoms/Icons/IconBody';
import IconLiability from 'component/atoms/Icons/IconLiability';
import IconPackage from 'component/atoms/Icons/IconPackage';
import { formButtonWrap } from 'component/molecules/Formrenderer/FormGridStyledComponent';
import { FormButtonProps } from 'component/molecules/Formrenderer/IFormDataGrid';

// Temporary solution till webMethods fixes the swagger issue
export const replaceJsonBlock = (jsonData: any, keyToReplace: string): any => {
  const newPaymentOption = {
    type: 'array',
    description:
      'The Payment Option aggregate contains payment information specifying a particular payment plan or the details needed to describe a payment plan in order to schedule payment of a full-term premium for a given XML transaction. Payment options can differ between coverage parts on a package policy so this aggregate would apply not only to a policy but to a coverage part as well.',
    items: {
      type: 'object',
      allOf: [
        {
          $ref: '#/components/schemas/PaymentOption_Type'
        }
      ],
      example: null
    },
    example: null
  };

  const findAndReplace = (obj: any): any => {
    if (typeof obj !== 'object' || obj === null) return obj;
    for (const key in obj) {
      if (key === keyToReplace) {
        obj[key] = newPaymentOption;
      } else if (typeof obj[key] === 'object') {
        obj[key] = findAndReplace(obj[key]);
      }
    }
    return obj;
  };

  return findAndReplace(jsonData); // Start recursion from the root of the JSON object
};

// Temporary solution till webMethods fixes the swagger issue
export const tryRemovingInConsistenciesOnlyIfRiskObject = (swaggerData: any) => {
  if (swaggerData && swaggerData.info?.title === 'riskobject' && swaggerData.info?.version === 'v1') {
    const registeration_properties = swaggerData?.components?.schemas?.Registration_Type?.properties;
    if (registeration_properties) {
      registeration_properties.RegistrationId = {
        type: 'object',
        properties: {
          '*body': {
            maxLength: 36,
            type: 'string',
            example: '58XKG3'
          }
        },
        example: null
      };
      registeration_properties.RegistrationStatusCd = {
        maxLength: 20,
        type: 'string',
        example: 'ACTV'
      };
    }
    const vehicle_properties = swaggerData?.components?.schemas?.Vehicle_Type?.properties;
    if (vehicle_properties) {
      vehicle_properties.ColorCd = {
        type: 'object',
        properties: {
          '*body': {
            maxLength: 2,
            type: 'string',
            description: 'The code for the color of the item/object. As used here, the exterior color of the vehicle.',
            example: '04'
          }
        }
      };
    }
  }
};

// Temporary solution till webMethods fixes the swagger issue
export const replaceWords = (text: string): string => {
  let updatedText = text;
  for (const [oldWord, newWord] of Object.entries(REPLACEMENTS) as [string, string][]) {
    const regex = new RegExp(`\\b${oldWord}\\b`, 'g'); // \b ensures whole word match
    updatedText = updatedText.replace(regex, newWord);
  }
  return updatedText;
};

export function filterPolicies(data: any): any {
  return {
    Policies: data.Policies.map((policyGroup: { Policy: any[] }) => ({
      Policy:
        policyGroup.Policy.length === 1
          ? policyGroup.Policy
          : policyGroup.Policy.filter((policy) => policy.LOBCd === 'PKG')
    }))
  };
}

export const getOverviewLink = (title: string) => {
  switch (title.toLowerCase()) {
    case 'package':
      return `/formrenderer/newpolicypackageautoliability`;
    case 'auto':
      return `/formrenderer/newpolicymonopersonalauto`;
    case 'liability':
      return `/formrenderer/newpolicymonopersonalliability`;
    case 'incident reporting':
      return `/formrenderer/fnol`;
    default:
      return '';
  }
};

export const keyGenerator = () => '_' + Math.random().toString(36).substr(2, 9);

export const getCountryCode = (input: any) => {
  const arr = countries.find((itm) => itm.phone === input);
  return arr?.code.toLowerCase() || DEFAULT_COUNTRY_CODE;
};

export const getStyles = (theme: Theme) => {
  return {
    fontWeight: theme.typography.fontWeightRegular
  };
};

export const readFileAsJson = <T,>(file: File): Promise<T> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      try {
        const data = JSON.parse(e.target?.result as string);
        resolve(data as T);
      } catch (error) {
        reject(new Error(INVALID_JSON_ERROR));
      }
    };
    reader.onerror = () => reject(new Error(FILE_READING_ERROR));
    reader.readAsText(file);
  });
};

export const addCustomClassToButtons = (components: IComponent[]) => {
  components.forEach((component) => {
    if (component.type === 'button') {
      if (component.customClass) {
        // Check if the class 'custom-button-class' is already present
        if (!component.customClass.split(' ').includes('custom-button-class')) {
          component.customClass += ' custom-button-class';
        }
      } else {
        component.customClass = 'custom-button-class';
      }
    }
    console.log(component.label, component);
    if (component.components && component.components.length > 0) {
      addCustomClassToButtons(component.components);
    }
  });
  return components;
};

export const isValidLanguageTranslation = (translations: LanguageTranslation[]): boolean => {
  // Check if translations is an array
  if (!Array.isArray(translations)) {
    return false;
  }

  // Check each translation object in the array
  for (const obj of translations) {
    // Check if obj is an object and has a data property
    if (typeof obj !== 'object' || obj === null || !obj.data) {
      return false;
    }

    const { data } = obj;

    // Check if data is an object
    if (typeof data !== 'object' || data === null) {
      return false;
    }

    // Check if data has a language property of type string
    if (typeof data.language !== 'string') {
      return false;
    }

    // Check if data has a translation property that is an object
    if (typeof data.translation !== 'object' || data.translation === null) {
      return false;
    }

    // Check if all values in translation are strings
    for (const key in data.translation) {
      if (typeof data.translation[key] !== 'string') {
        return false;
      }
    }
  }

  // If all checks pass, return true
  return true;
};

export const caseinsensitive = (string: string) => {
  if (string.length === 0) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const readFileAsText = <T,>(file: File): Promise<T> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      try {
        const data = e.target?.result as string;
        resolve(data as T);
      } catch (error) {
        reject(new Error('Invalid JSON format'));
      }
    };
    reader.onerror = () => reject(new Error('File reading failed'));
    reader.readAsText(file);
  });
};

export const isValidSwaggerFile = (file: File): boolean => {
  return /.json|.yml|.yaml$/i.test(file.name);
};

export const isValidjsonFile = (file: File): boolean => {
  return /.json/i.test(file.name);
};

export const clearFile = (event: ChangeEvent<HTMLInputElement>): void => {
  if (event.target) {
    event.target.value = '';
  }
};

export const toJsonExtension = (name: string) => {
  const replaceName = name.replace(/\.ya?ml/g, '.json');
  return replaceName;
};

export const downloadFormAsJson = (fileContent: object | any[]) => {
  const jsonString = JSON.stringify(fileContent, null, 2);
  const blob = new Blob([jsonString], { type: 'application/json' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');

  // Determine the filename
  const defaultFilename = 'language.json';
  let filename = defaultFilename;

  if (Array.isArray(fileContent)) {
    filename =
      fileContent.length > 0 && typeof fileContent[0] === 'object' && fileContent[0] !== null
        ? (fileContent[0] as { name?: string }).name || defaultFilename
        : defaultFilename;
  } else if (typeof fileContent === 'object' && fileContent !== null) {
    filename = (fileContent as { name?: string }).name || defaultFilename;
  }

  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

export const isValidSwaggerJson = (json: any) => {
  if (typeof json !== 'object' || json === null) {
    return false;
  }

  if (!('info' in json) || !('paths' in json)) {
    return false;
  }

  if (typeof json.info !== 'object' || json.info === null) {
    return false;
  }

  if (!('title' in json.info) || !('version' in json.info)) {
    return false;
  }

  if (typeof json.info?.title !== 'string' || typeof json.info.version !== 'string') {
    return false;
  }

  if (typeof json.paths !== 'object' || json.paths === null) {
    return false;
  }

  return true;
};

export function camelize(str: string): string {
  const cleanedStr = str.replace(/[^a-zA-Z\s]/g, '');
  const words = cleanedStr.split(' ').filter(Boolean);
  words.forEach((word, index) => {
    if (index > 0) {
      words[index] = word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
  });
  const camelizedStr = words.join('');
  return camelizedStr ? camelizedStr.charAt(0).toLowerCase() + camelizedStr.slice(1) : '';
}

export const iconDataArray = [
  { key: 'package', value: 'Package', icon: <IconPackage /> },
  { key: 'auto', value: 'Auto', icon: <IconCarside /> },
  { key: 'liability', value: 'Liability', icon: <IconLiability /> },
  { key: 'incident', value: 'Incident', icon: <IncidentIcon /> },
  { key: 'invoice', value: 'Invoice', icon: <IconInvoice width='25' height='25' /> },
  { key: 'contact', value: 'Contact', icon: <IconContact width='25' height='25' /> },
  { key: 'chat', value: 'Chat', icon: <IconChat width='25' height='25'  /> },
  { key: 'document', value: 'Document', icon: <IconDocument width='25' height='25' /> },
  { key: 'house', value: 'House', icon: <IconHouse width='25' height='25'  /> },
  { key: 'insurance', value: 'Insurance', icon: <IconInsuranceSolid width='25' height='25'  /> },
  { key: 'car', value: 'Car', icon: <IconCar width='25' height='25' /> },
  { key: 'personLuggage', value: 'Person', icon: <IconPerson width='25' height='25'  /> },
  { key: 'person', value: 'Person Luggage', icon: <IconBodyLuggage width='25' height='25'  /> },
  { key: 'insurancecar', value: 'Person Circle', icon: <IconInsuranceCar width='38' height='38'  /> },
  { key: 'insurancecar1', value: 'Shield Circle', icon: <IconInsuranceCar1 width='38' height='38'  /> },
  { key: 'insurancecar2', value: 'Car Circle', icon: <IconInsuranceCar2 width='38' height='38'  /> },
  { key: 'insurancecar3', value: 'Home Circle', icon: <IconInsuranceCar3  width='38' height='38' /> },
  { key: 'iconTruck', value: 'Truck', icon: <IconTruck  width='30' height='30' /> },
  { key: 'iconBicycle', value: 'Bicycle', icon: <IconBicycle  width='30' height='20' /> },
  { key: 'iconVehicle', value: 'Vehicle', icon: <IconVehicle  width='30' height='30' /> },
  { key: 'sideTruck', value: 'Side Car', icon: <IconSideTruck  width='30' height='30' /> },
  { key: 'iconVehicleTransport', value: 'Vehicle Transport', icon: <IconVehicleTransport  width='24' height='24' /> },
  { key: 'iconCarAndDriver', value: 'Tractor', icon: <IconCarAndDriver  width='30' height='30' /> },
  { key: 'iconDocumentEdit', value: 'Medical Case', icon: <IconDocumentEdit  width='30' height='30' /> },
  { key: 'iconPlusSquare', value: 'Icon Plus', icon: <IconPlusSquare  width='30' height='30' /> },
  { key: 'iconMountain', value: 'Plane Departure', icon: <IconMountain  width='30' height='30' /> },
  { key: 'iconBook', value: 'Suit Box', icon: <IconBook  width='30' height='30' /> },
  { key: 'iconCaravanTrail', value: 'Caravan', icon: <IconCaravanTrail  width='30' height='30' /> },
  { key: 'iconBalance', value: 'Balance', icon: <IconBalance  width='30' height='30' /> },
  { key: 'iconWheelChair', value: 'Wheel Chair', icon: <IconWheelChair  width='30' height='30' /> },
  { key: 'personFall', value: 'Person Fall', icon: <PersonFall  width='30' height='30' /> },
  { key: 'iconTruckTransport', value: 'Truck Transport', icon: <IconTruckTransport  width='30' height='30' /> },
  { key: 'iconTruckMedical', value: 'Truck Medical', icon: <IconTruckMedical  width='30' height='30' /> },
  { key: 'iconCouch', value: 'Couch', icon: <IconCouch  width='30' height='30' /> },
  { key: 'iconHouseLaptop', value: 'House Laptop', icon: <IconHouseLaptop  width='30' height='30' /> },
  { key: 'iconHouseLight', value: 'House Light', icon: <IconHouseLight  width='30' height='30' /> },
  { key: 'default', value: 'Default', icon: <IconGlobe width='25' height='25' /> }
];

export const getOverviewIconData = (title: string) => {
  const lowerTitle = title.toLowerCase();
  return iconDataArray.find((item) => item.key.toLowerCase() === lowerTitle.toLowerCase()) || iconDataArray.find((item) => item.key === 'default');
};

export const generateOverviewUrl = (overviewItemUrl: string, partynumber?: string): string => {
  const baseUrl = overviewItemUrl
    ? (overviewItemUrl.startsWith('/formrenderer') ? overviewItemUrl : `/formrenderer${overviewItemUrl}`)
    : '#';
  return partynumber ? `${baseUrl}/${partynumber}` : baseUrl;
};

export const FormButton: React.FC<FormButtonProps> = ({ onClick, icon, label }) => (
  <Button variant='contained' sx={formButtonWrap} onClick={onClick}>
    {icon}
    {label}
  </Button>
);
  
export function sanitizeText(str: string): string {
  const cleanedStr = str.replace(/[^a-zA-Z1-9\s]/g, '');
  const words = cleanedStr.split(' ').filter(Boolean);
  const lowerCaseWords = words.reduce((acc: string[], itm: string) => {
    itm.toLowerCase();
    acc.push(itm);
    return acc;
  }, []);
  const clearStr = lowerCaseWords.join('');
  return clearStr;
}

export function generateRandomId(length = 10) {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = length; i > 0; --i) {
    result += chars[Math.floor(Math.random() * chars.length)];
  }
  return result;
}
const truncateToKeywordsCamelCase = (sentence: string, maxWords = 3, maxKeywordLength = 15) => {
  return sentence
    .replace(/[^\w\s]/g, '')
    .split(/\s+/)
    .filter((word) => word.length > 0)
    .slice(0, maxWords)
    .map((word) => word.slice(0, maxKeywordLength))
    .map((word, index) =>
      index === 0 ? word.toLowerCase() : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    )
    .join('');
};

export const updateKey = (obj: any, updateKey: boolean = false) => {
  // Early return if the key is already valid and updateKey is false
  if (!updateKey && obj.key && obj.key !== '$$empty') return obj;

  // Get the value based on language preference
  const getValue = (lang: 'nl' | 'en') => obj[lang]?.default || obj[lang]?.custom;
  const value = getValue('en') || getValue('nl');

  // Generate the key if it's missing or invalid
  if (!obj.key || obj.key === '$$empty') {
    obj.key = value ? truncateToKeywordsCamelCase(value) : '$$empty';
  }

  return obj;
};

export const contentText = {
  color: '#00005A',
  fontSize: '14px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
};

export const getPhoneNumber = (itm: any) => {
  return (
    <>
      {itm
        ? itm.map((item: any, index: any) => (
            <Grid container spacing={2} key={index} item xs={12} alignItems='center'>
              <Grid item xs={6}>
                <Typography component={'span'} sx={phoneNumberStyle}>
                  {item[PHONE_TYPE_CD]}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography component={'span'} sx={phoneNumberStyle}>
                  {item[PHONE_NUMBER]}
                </Typography>
              </Grid>
            </Grid>
          ))
        : ''}
    </>
  );
};

export const getCurrencySymbol = (currencyCode: string) => {
  return CURRENCY_SYMBOLS[currencyCode] || '';
};

export const archiveDownload = (archiveNumber: string, showSnackBar: Function) => {
  const service = new ArchiveService();

  service
    .get(archiveNumber)
    .then((archive: IArchive) => {
      const { FileAttachmentInfo } = archive;
      const { AttachmentData, MIMEContentTypeCd, AttachmentFilename } = FileAttachmentInfo;
      const binaryString = atob(AttachmentData.BinData);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);

      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      const blob = new Blob([bytes], { type: MIMEContentTypeCd.Value });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = AttachmentFilename + ARCHIVE_FILE_DOWNLOAD_EXTENSION + MIMEContentTypeCd.Value;
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    })
    .catch(() => {
      showSnackBar(SNACKBAR_ERROR_MESSAGE, ERROR);
    });
};

export const hasValues = (obj: any): boolean => {
  if (!obj || typeof obj !== 'object') return false;
  return Object.values(obj).some((value) => value !== null && value !== undefined && value !== '');
};
