import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Box,
  Autocomplete,
  IconButton,
  Tooltip
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { iconDataArray } from 'utils/Utils';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

interface OverviewDialogProps {
  open: boolean;
  title: string;
  description: string;
  url: string;
  icon: string | null;
  onClose: () => void;
  onSave: () => void;
  onTitleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onDescriptionChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onUrlChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onIconChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  editMode: boolean;
}

const OverviewDialog: React.FC<OverviewDialogProps> = ({
  open,
  title,
  description,
  url,
  icon,
  onClose,
  onSave,
  onTitleChange,
  onDescriptionChange,
  onUrlChange,
  onIconChange,
  editMode
}) => {
  const { t } = useTranslation();
  const [selectedIconKey, setSelectedIconKey] = useState<string | null>(icon);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (open && editMode && icon) {
      setSelectedIconKey(icon);
    }
  }, [open, editMode, icon]);

  useEffect(() => {
    setSelectedIconKey(icon);
  }, [icon]);

  const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const newValue = inputValue.replace(/^\/?formrenderer\/?/, '/').replace(/^([^/])/, '/$1');
    onUrlChange({ ...e, target: { ...e.target, value: newValue } });
  };

  const handleIconChange = (_: any, newValue: { key: string; value: string; icon: JSX.Element } | null) => {
    const newIconKey = newValue?.key || '';
    setSelectedIconKey(newIconKey);
    if (newIconKey) {
      onIconChange({ target: { value: newIconKey } } as React.ChangeEvent<HTMLInputElement>);
    }
  };

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(url);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); 
  };

  const handleSave = () => {
    onSave();
  };

  const selectedIcon = iconDataArray.find((item) => item.key === selectedIconKey)?.icon;
  const isSaveDisabled = title.length > 32 || description.length > 32;

  return (
    <Dialog open={open} onClose={undefined}>
      <DialogTitle sx={{ position: 'relative' }}>
        {editMode ? t('editOverviewItem') : t('newOverviewItem')}
        <IconButton
          sx={{ position: 'absolute', top: '0px', right: '0px' }}
          onClick={onClose}
          color='inherit'
          aria-label='close'>
          <HighlightOffIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin='dense'
          label={`${t('Title')}`}
          required={true}
          type='text'
          fullWidth
          variant='outlined'
          inputProps={{ maxLength: 32 }}
          placeholder={t('placeholderTitle')}
          value={title}
          onChange={onTitleChange}
        />
        <TextField
          margin='dense'
          label={`${t('Description')}`}
          type='text'
          fullWidth
          required={true}
          variant='outlined'
          inputProps={{ maxLength: 32 }}
          placeholder={t('placeholderDescription')}
          value={description}
          onChange={onDescriptionChange}
        />
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            margin='dense'
            label={`${t('URL')}`}
            type='text'
            sx={{ width: '300px' }}
            placeholder={t('placeholderURL')}
            required={true}
            fullWidth
            variant='outlined'
            value={url}
            onChange={handleUrlChange}
          />
          <Tooltip title={copied ? "Copied!" : ""} open={copied} onClose={() => setCopied(false)}>
            <IconButton onClick={handleCopyUrl} color='primary' aria-label='copy URL' sx={{ ml: 1 }}>
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
          <Autocomplete
            options={[...iconDataArray].sort((a, b) => a.value.localeCompare(b.value))}
            sx={{ width: '300px' }}
            getOptionLabel={(option) => (option && 'value' in option ? option.value : '')}
            value={iconDataArray.find((item) => item.key === selectedIconKey) || null}
            onChange={handleIconChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('selectIcon')} 
                placeholder={t('placeholderSelectIcon')}
                required
                fullWidth
                sx={{ width: '100%' }}
              />
            )}
            renderOption={(props, option) => (
              <Box
                component='li'
                {...props}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  p: 1,
                  gap: 1
                }}>
                <Box sx={{ flexGrow: 1, textAlign: 'left' }}>{option.value}</Box>
                <Box sx={{ flexShrink: 0 }}>{option.icon}</Box>
              </Box>
            )}
          />
          {selectedIcon && <Box sx={{ ml: 2, display: 'flex', alignItems: 'center' }}>{selectedIcon}</Box>}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='secondary'>
          {t('Cancel')}
        </Button>
        <Button onClick={handleSave} color='primary' disabled={isSaveDisabled}>
          {editMode ? t('edit') : t('add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OverviewDialog;
