export type formioEventHandlerType = (eventData: any, instance: any, emitEventName: string) => void;

// To attach/append the event handlers on to formio instance object
export const attachEventHandler = (
  instance: any,
  eventName: string,
  emitEventName: string,
  action: formioEventHandlerType
) => {
  const eventHandler = (eventData: any) => action(eventData, instance, emitEventName);
  const listenerKeys = new Set<string>();
  const instanceKey = (instance.key ?? '') as string;

  instance.events.listeners(`formio.${eventName}`).forEach((listener: any) => {
    const listenerKey = (listener.key ?? '') as string;

    // Remove the listener if it's from a different instance and is already tracked
    if ((listenerKeys.has(listenerKey) || listenerKey === instanceKey) && listener.id !== instance.id) {
      instance.events.off(`formio.${eventName}`, listener);
    }
    listenerKeys.add(listenerKey);
  });

  // Attach the new event handler if it hasn't been added yet
  if (!listenerKeys.has(instanceKey)) {
    instance.on(eventName, eventHandler);
  }
};
