import { useTheme } from '@mui/material/styles';
function IconContact(_props: any) {
  const theme = useTheme();
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={_props.width ? _props.width : '16'}
      height={_props.height ? _props.height : '16'}
      viewBox='0 0 16 16'>
      <path fill='none' d='M0 0h16v16H0z' data-name='Path 228'></path>
      <defs>
        <linearGradient id='duotoneGradient' x1='0%' y1='0%' x2='100%' y2='100%'>
          <stop offset='0%' style={{ stopColor: theme.palette.primary.main, stopOpacity: 1 }} />
          <stop offset='100%' style={{ stopColor: theme.palette.secondary.main, stopOpacity: 1 }} />
        </linearGradient>
      </defs>
      <path
        fill='url(#duotoneGradient)'
        d='M13.34 11.102a7.629 7.629 0 01-2.353-.373.652.652 0 00-.673.16l-1.047 1.313a10.122 10.122 0 01-4.594-4.553l1.3-1.107a.68.68 0 00.16-.68 7.435 7.435 0 01-.373-2.353.666.666 0 00-.66-.66H2.793c-.36 0-.793.16-.793.66a11.435 11.435 0 0011.34 11.34.7.7 0 00.66-.787v-2.3a.666.666 0 00-.66-.66z'
        data-name='Path 229'></path>
    </svg>
  );
}

export default IconContact;
