import React, { useEffect, useState } from 'react';
import { FormControlLabel, FormGroup, styled } from '@mui/material';

const ThemeSwitch = styled('div')({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#e8e8e8',
  borderRadius: '999px',
  padding: '2px',
  position: 'relative',
  cursor: 'pointer',
  width: '120px',
  height: '32px',
  userSelect: 'none'
});

interface SliderProps {
  isChecked: boolean;
}

const Slider = styled('div')<SliderProps>(({ isChecked }) => ({
  position: 'absolute',
  backgroundColor: 'white',
  borderRadius: '999px',
  transition: 'transform 0.2s ease',
  width: '60px',
  height: '28px',
  transform: isChecked ? 'translateX(58px)' : 'translateX(2px)',
  boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)'
}));

interface LabelProps {
  isActive: boolean;
  children: React.ReactNode;
}

const LabelContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  position: 'relative',
  zIndex: 2
});

const Label = styled('span')<LabelProps>(({ isActive }) => ({
  color: isActive ? '#000' : '#666',
  fontSize: '14px',
  fontWeight: 500,
  padding: '0 12px',
  transition: 'color 0.2s ease'
}));

interface ThemeSwitchComponentProps {
  viewComponent?: boolean;
  changeHandler?: (checked: boolean) => void;
  leftLabel?: string;
  rightLabel?: string;
}

const ThemeSwitchComponent = ({ 
  viewComponent = false, 
  changeHandler,
  leftLabel = 'Light',
  rightLabel = 'Dark'
}: ThemeSwitchComponentProps) => {
  const [isChecked, setIsChecked] = useState<boolean>(Boolean(viewComponent));

  const handleToggle = () => {
    const newValue = !isChecked;
    setIsChecked(newValue);
    if (changeHandler) {
      changeHandler(newValue);
    }
  };

  useEffect(() => {
    setIsChecked(Boolean(viewComponent));
  }, [viewComponent]);

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <ThemeSwitch onClick={handleToggle}>
            <Slider isChecked={isChecked} />
            <LabelContainer>
              <Label isActive={!isChecked}>{leftLabel}</Label>
              <Label isActive={isChecked}>{rightLabel}</Label>
            </LabelContainer>
          </ThemeSwitch>
        }
        label=""
      />
    </FormGroup>
  );
};

export default ThemeSwitchComponent;