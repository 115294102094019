
import { useTheme } from '@mui/material/styles';

function IconPerson(_props:any) {
  const theme = useTheme();
  
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={_props.width ? _props.width : '20'}
      height={_props.height ? _props.height : '20'}
      viewBox="0 0 320 512"
    >
      <defs>
        <linearGradient id="duotoneGradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" style={{ stopColor: theme.palette.primary.main, stopOpacity: 1 }} />
          <stop offset="100%" style={{ stopColor: theme.palette.secondary.main, stopOpacity: 1 }} />
        </linearGradient>
      </defs>
      <path fill="none" d="M0 0h320v512H0z" />
      <path
        fill="url(#duotoneGradient)"
        d="M112 48a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm40 304l0 128c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-223.1L59.4 304.5c-9.1 15.1-28.8 20-43.9 10.9s-20-28.8-10.9-43.9l58.3-97c17.4-28.9 48.6-46.6 82.3-46.6l29.7 0c33.7 0 64.9 17.7 82.3 46.6l58.3 97c9.1 15.1 4.2 34.8-10.9 43.9s-34.8 4.2-43.9-10.9L232 256.9 232 480c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-128-16 0z"
      />
    </svg>
  );
}

export default IconPerson;
