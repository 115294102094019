
import { useTheme } from '@mui/material/styles';
function IconInsuranceCar1(_props: any) {
    const theme = useTheme();
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={_props.width ? _props.width : "38"}
            height={_props.height ? _props.height : "38"}
            viewBox="0 0 38 38"
        >
             <defs>
        <linearGradient id='duotoneGradient' x1='0%' y1='0%' x2='100%' y2='100%'>
          <stop offset='0%' style={{ stopColor: theme.palette.primary.main, stopOpacity: 1 }} />
          <stop offset='100%' style={{ stopColor: theme.palette.secondary.main, stopOpacity: 1 }} />
        </linearGradient>
      </defs>
            <circle
                cx="19"
                cy="19"
                r="19"
                fill="rgba(0,70,255,.05)"
                data-name="Ellipse 467"
            ></circle>
            <path fill="none" d="M10 10.019h18v18H10z" data-name="Path 3661"></path>
            <path
                fill='url(#duotoneGradient)'
                d="M19 10.769l-6.75 3v4.5a9.334 9.334 0 006.75 9 9.334 9.334 0 006.75-9v-4.5zm5.25 7.5A7.855 7.855 0 0119 25.716a7.855 7.855 0 01-5.25-7.447v-3.525L19 12.412l5.25 2.332zm-8.692.443L14.5 19.769l3 3 6-6-1.057-1.065-4.943 4.943z"
                data-name="Path 3662"
            ></path>
        </svg>
    );
}

export default IconInsuranceCar1;