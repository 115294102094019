import { useTheme } from '@mui/material/styles';

const IconIncident = () => {
    const theme = useTheme();
    return(
  <svg xmlns="http://www.w3.org/2000/svg"  width='25px' height='25px' viewBox="0 0 640 512">
    <defs>
    <linearGradient id="IconIncident" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" style={{ stopColor: theme.palette.primary.main, stopOpacity: 1 }} />
          <stop offset="100%" style={{ stopColor:  theme.palette.secondary.main, stopOpacity: 1 }} />
        </linearGradient>
    </defs>
    <path
      d="M176 8c-6.6 0-12.4 4-14.9 10.1l-29.4 74-76.1-23.2c-6.3-1.9-13.1.2-17.2 5.3S33.8 86.4 37 92.1l39.5 69.1-65.6 45.2c-5.4 3.7-8 10.3-6.5 16.7s6.7 11.2 13.1 12.2l78.7 12.2-5.6 79.5c-.5 6.5 3.1 12.7 9 15.5s12.9 1.8 17.8-2.6l35.3-32.5 9.5-35.4 10.4-38.6c8-29.9 30.5-52.1 57.9-60.9l41-59.2C282.8 97 297.9 84.4 315 76.1c-.4-.6-.8-1.2-1.3-1.8-4.1-5.1-10.9-7.2-17.2-5.3l-76.2 23.1-29.4-74C188.4 12 182.6 8 176 8zm191.7 153.5 135.6 36.3c6.5 1.8 11.3 7.4 11.8 14.2l4.6 56.5-201.5-54 32.2-46.6c3.8-5.6 10.8-8.1 17.3-6.4zm-69.9-30-47.9 69.3c-21.6 3-40.3 18.6-46.3 41l-10.4 38.6-16.6 61.8-8.3 30.9c-4.6 17.1 5.6 34.6 22.6 39.2l15.5 4.1c17.1 4.6 34.6-5.6 39.2-22.6l8.3-30.9 247.3 66.3-8.3 30.9c-4.6 17.1 5.6 34.6 22.6 39.2l15.5 4.1c17.1 4.6 34.6-5.6 39.2-22.6l8.3-30.9L595 388l10.4-38.6c6-22.4-2.5-45.2-19.6-58.7l-6.8-84c-2.7-33.7-26.4-62-59-70.8L384.2 99.7c-32.7-8.8-67.3 4-86.5 31.8zm-17 131a24 24 0 1 1-12.4 46.4 24 24 0 1 1 12.4-46.4zm217.9 83.2a24 24 0 1 1 46.3 12.4 24 24 0 1 1-46.4-12.4z"
      fill="url(#IconIncident)" // Apply the gradient here
    />
  </svg>
)};

export default IconIncident;
