import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
  GridRowsProp,
  GridRowModesModel
} from '@mui/x-data-grid-pro';
import { styled } from '@mui/material/styles';

interface CustomDataGridToolbarProps {
  title?: string;
  type?: string;
  setRows?: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel?: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
}

const CustomToolbarContainer = styled(GridToolbarContainer)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(1),
  color:theme.palette.text.primary,
  backgroundColor: '#f0f0f0',
 
}));

const ToolbarButtons = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  color:theme.palette.text.primary,

}));

const ToolbarQuickFilter = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 'auto'
}));

const ToolbarTitle = styled('div')(() => ({
  flexGrow: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: 'bold',
  fontSize: '1rem',
  fontStyle: 'italic',
  textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
  color: 'primary.main'
}));

const CustomDataGridToolbar = ({ title }: CustomDataGridToolbarProps) => {
  return (
    <CustomToolbarContainer>
      <ToolbarButtons>
        <GridToolbarColumnsButton sx={{color:(theme:any)=>theme.palette.text.primary}} />
        <GridToolbarFilterButton sx={{color:(theme:any)=>theme.palette.text.primary}}/>
        <GridToolbarDensitySelector sx={{color:(theme:any)=>theme.palette.text.primary}} />
      </ToolbarButtons>
      {title && <ToolbarTitle>{title}</ToolbarTitle>}
      <ToolbarQuickFilter>
        <GridToolbarQuickFilter />
      </ToolbarQuickFilter>
    </CustomToolbarContainer>
  );
};

export const DataGridProSx = {
  height: '95%',
  width: '100%',
  '& .MuiDataGrid-virtualScroller': {
    overflow: 'auto'
  },
  '& .MuiDataGrid-columnHeaders': {
    height: '36px !important',
    color: 'white',
    bgcolor: 'secondary.main',
    button: {
      color: 'white'
    },
    '.MuiCheckbox-colorPrimary': {
      color: 'white'
    }
  }
};

export default CustomDataGridToolbar;
