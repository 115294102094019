import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputAdornment from '@mui/material/InputAdornment';
import { useTranslation } from 'react-i18next';
import { InputLable } from '../InputLabel/InputLabel';
import { emailTypeFeildStyle, menuItemStyle } from './EmailTypeFeildStyle';
import { OutlinedFormInput } from 'component/atoms/OutlinedInput/OutlinedInput';

export function Emailtypefield(props: any) {
  const { t } = useTranslation<string>();

  return (
    <FormControl sx={emailTypeFeildStyle} variant='standard' fullWidth>
      <InputLable htmlFor='email'>{t('email')}</InputLable>
      <OutlinedFormInput
        id='emailOutline'
        value={props.value}
        sx={{
          background: 'transparent',
          color: (theme) => theme.palette.text.primary,
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: (theme) => `${theme.palette.grey[400]} !important`
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: (theme) => `${theme.palette.primary.main} !important`
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: (theme) => `${theme.palette.secondary.main} !important`
          }
        }}
        name={props.name}
        onChange={props.onChange}
        fullWidth
        endAdornment={
          <InputAdornment position='end'>
            <FormControl variant='outlined' sx={{ minWidth: 110, border: 0, pr: 1.5 }}>
              <Select labelId='email' id='email' value={'Work'} className='selectStyle'>
                <MenuItem selected value={'Work'} sx={menuItemStyle}>
                  {t('work')}
                </MenuItem>
                <MenuItem value={'Private'} sx={menuItemStyle}>
                  {t('private')}
                </MenuItem>
              </Select>
            </FormControl>
          </InputAdornment>
        }
      />
    </FormControl>
  );
}
