import { alpha, Menu, MenuProps, styled } from '@mui/material';
import DraftsIcon from '@mui/icons-material/Drafts';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Theme } from '@mui/material';

export const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0'
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
      }
    }
  }
}));

export const setBackgroundColor = (status: string) => {
  const menu = { color: 'blue', icon: <AddCircleIcon />, text: 'Draft' };

  switch (status.toLowerCase()) {
    case 'final':
      menu.color = 'orange';
      menu.icon = (
        <UnpublishedIcon sx={{ fontSize: '20px', mr: 1, color: (theme: Theme) => theme.palette.secondary.main }} />
      );
      menu.text = 'Final';
      break;
    case 'publish':
      menu.color = 'green';
      menu.icon = (
        <PublishedWithChangesIcon
          sx={{ fontSize: '20px', mr: 1, color: (theme: Theme) => theme.palette.secondary.main }}
        />
      );
      menu.text = 'Publish';
      break;
    default:
      menu.color = 'blue';
      menu.icon = (
        <DraftsIcon sx={{ fontSize: '20px', mr: 1, color: (theme: Theme) => theme.palette.secondary.main }} />
      );
      menu.text = 'Draft';
  }
  return menu;
};

export const saveButtonWrapper = () => ({
  display: 'inline-flex ',
  marginLeft: 'auto',
  bgcolor: (theme: Theme) => theme.palette.secondary.main,
  '& .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium': {
    color: '#fff'
  }
});
export const menuOptions = [
  'draft',
  'final',
  'publish'
];

export const outline={
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#BOBOBO'
    },
    '&:hover fieldset': {
      borderColor: '#BOBOBO'
    },
    '&.Mui-focused fieldset': {
      borderColor: '#BOBOBO'
    }
  }
}

export const selectOutline={
  mr:1,
  borderColor: (theme:any)=>theme.palette.secondary.main, // Example: white for dark theme
  '& fieldset': {
    borderColor: (theme:any)=>theme.palette.secondary.main, // Ensures the fieldset gets the border color
  }
}