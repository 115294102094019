/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { i18n } from 'i18next';
import { Form, Formio, Utils } from '@formio/react';

import { useSnackBar } from 'context/Snackbar/Snackbar';
import { createErrorFetchPlugin } from 'utils/formio/createErrorFetchPlugin';
import { setOpenBriefView } from 'store/briefview/briefViewDrawerSlice';
import { LanguageCode, NamespaceCode } from 'entities/Translation/TranslationService';

import './FormStyles.css';
import FormioService from 'entities/Formio/FormioService';
import { Box } from '@mui/material';
import { IForm, IFormioSubmissionResponse, ISubmission } from '@ccs-dip/common/types/formio-types';
import { FormioSubmissionService } from 'entities/FormioSubmission/FormioSubmissionService';
import { Options } from '@formio/react/lib/components/Form';
import { onCustomEvent } from './onCustomEventHandler';

//===============================================
// private variables
//===============================================

type ParamType = 'partynumber' | 'policyparams' | 'lobcd';

const prepareInitialSubmission = (submission: ISubmission, type: ParamType, value: string) => {
  switch (type) {
    case 'partynumber':
      submission.data['PartyNumber'] = value;
      break;
    case 'policyparams': {
      const splitIndex = value.lastIndexOf('-');
      const policynumber = value.substring(0, splitIndex);
      const policyversion = value.substring(splitIndex + 1);
      submission.data['PolicyNumber'] = policynumber;
      submission.data['PolicyVersion'] = policyversion;
      break;
    }
    default:
      console.log('Invaild type of parameter');
  }
};

const getTranslations = (i18n: i18n, lang: LanguageCode) => {
  const default_translations = i18n.getResourceBundle(lang, 'default' as NamespaceCode);
  const custom_translations = i18n.getResourceBundle(lang, 'custom' as NamespaceCode);
  return { ...default_translations, ...custom_translations };
};

interface IFormRenderer {
  formsrc?: string;
}

interface IFormOptions extends Options {
  buttonSettings: object;
}

//===============================================
// Component render function
//===============================================

const FormRenderer = (props: IFormRenderer) => {
  const params = useParams();
  const formsrc = props.formsrc || params['formsrc'];
  const partynumber = params['partynumber'] || '';
  const lobcd = params['type'] || '';
  const policyparams = params['policyparams'];
  const [instance, setInstance] = useState<any>();
  const [formio, setFormio] = useState<IForm | undefined>();
  const { showSnackBar } = useSnackBar();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const translations = {
    nl: getTranslations(i18n, 'nl'),
    en: getTranslations(i18n, 'en')
  };
  const className = formsrc === 'insuranceoverview' ? 'hiddenPanel' : 'defaultForm';
  const isValidPartynumber = /^\d+$/.test(partynumber);

  if (!!partynumber && !isValidPartynumber) {
    showSnackBar('Partynumber is invalid. It must contain only numbers.', 'error');
  }
  const service = new FormioService();
  // register error fetch-plugin
  Formio.registerPlugin(createErrorFetchPlugin(showSnackBar), createErrorFetchPlugin.name);

  useEffect(() => {
    dispatch(setOpenBriefView(false));
    service
      .getForms(formsrc)
      .then((response: any) => {
        if (Object.keys(response).length) {
          if (response) {
            setFormio(response);
          }
          // Provide feedback to the user
        }
      })
      .catch(() => {
        showSnackBar('No forms available', 'error');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formsrc]);

  useEffect(() => {
    if (instance) {
      instance.root.addLanguage(i18n.language, translations[i18n.language as 'nl' | 'en']);
      // Warning instance will reset page after change language...!!!
      const page = instance.root.page;
      instance.root.language = i18n.language;
      if (instance.root.setPage && instance.root.page !== page) {
        setTimeout(() => instance.root.setPage(page), 1000);
      }
    }
    i18n.changeLanguage(i18n.language);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance, i18n.language]);

  const options: IFormOptions = {
    i18n: {
      language: i18n.language,
      ...translations
    },
    buttonSettings: {
      showSubmit: false
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column', minHeight: 500 }}>
        <Box id='FormRenderer' sx={{ width: '100%', p: '30px' }} className={className}>
          {formio && Object.keys(formio).length ? (
            <Box className={formio.display !== 'form' ? 'custom-form-builder-wizard' : ''}>
              <Form
                key={formsrc}
                form={formio}
                formReady={async (instance: any) => {
                  // TODO: Refactoring of formReady is needed, becasue it should be fetching
                  const submission = JSON.parse(JSON.stringify(instance.root.submission)) as ISubmission;
                  const fallBackSetSubmission = () =>
                    instance.root.setSubmission(Utils._.merge(submission, instance.root.submission));
                  const operations = [] as string[];
                  const transactionId = Utils.getRandomComponentId();

                  setInstance(instance);
                  instance.root.language = i18n.language;
                  submission.transactionId = transactionId;
                  if (lobcd) {
                    const submission = instance.root.submission as ISubmission;
                    if (!submission.data.Policy) {
                      submission.data.Policy = {};
                    }
                    submission.data.Policy.LOBCd = lobcd.toUpperCase();
                  }

                  if (partynumber) {
                    prepareInitialSubmission(submission, 'partynumber', partynumber);
                    operations.push('readParty');
                  }
                  if (policyparams) {
                    prepareInitialSubmission(submission, 'policyparams', policyparams);
                    operations.push('readPolicy');
                  }
                  if (operations.length) {
                    const service = new FormioSubmissionService();
                    await service
                      .send(instance.root?.form?.name, operations.join(' '), submission)
                      .then((response: IFormioSubmissionResponse) => {
                        instance.root.setSubmission(Utils._.merge(submission, response.submission));
                      })
                      .catch(() => fallBackSetSubmission());
                  } else {
                    // The submission data should be set othewise form cannot detect the change
                    fallBackSetSubmission();
                  }
                }}
                onCustomEvent={(eventData: any) => onCustomEvent(eventData, showSnackBar, i18n)}
                options={options}
              />
            </Box>
          ) : (
            ''
          )}
        </Box>
      </Box>
    </>
  );
};

export default FormRenderer;
