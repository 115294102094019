import { useTheme } from '@mui/material/styles';

function IconVehicleTransport(props: any) {
  const theme = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || '20'}
      height={props.height || '20'}
      viewBox="0 0 512 512"
      aria-labelledby="icon-vehicle-transport-title"
      role="img"
    >
      <title id="icon-vehicle-transport-title">Vehicle Transport Icon</title>
      <defs>
        <linearGradient id="vehicleGradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" style={{ stopColor: theme.palette.primary.main, stopOpacity: 1 }} />
          <stop offset="100%" style={{ stopColor: theme.palette.secondary.main, stopOpacity: 1 }} />
        </linearGradient>
      </defs>
      <path fill="none" d="M0 0h512v512H0z" data-name="background" />
      <path
        fill="url(#vehicleGradient)"
        d="M192 0c-17.7 0-32 14.3-32 32v32v.2c-38.6 2.2-72.3 27.3-85.2 64.1L39.6 228.8C16.4 238.4 0 261.3 0 288v144v48c0 17.7 14.3 32 32 32h32c17.7 0 32-14.3 32-32v-48h320v48c0 17.7 14.3 32 32 32h32c17.7 0 32-14.3 32-32v-48V288c0-26.7-16.4-49.6-39.6-59.2L437.2 128.3c-12.9-36.8-46.6-62-85.2-64.1v-.2V32c0-17.7-14.3-32-32-32H192zM165.4 128h181.2c13.6 0 25.7 8.6 30.2 21.4L402.9 224H109.1l26.1-74.6c4.5-12.8 16.6-21.4 30.2-21.4zM96 288a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm288 32a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"
        data-name="vehicle-transport-path"
      />
    </svg>
  );
}

export default IconVehicleTransport;
