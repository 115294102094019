import { useEffect, useState } from 'react';
import { Avatar, Backdrop, Box, Divider, Grid, Stack, Typography } from '@mui/material';
import { IParty } from 'entities/Party/IParty';
import PartyService from 'entities/Party/PartyService';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { contentText, getPhoneNumber, hasValues } from 'utils/Utils';
import Loader from 'component/organisms/Loader/Loader';
import Tooltip from '@mui/material/Tooltip';
import { useSnackBar } from 'context/Snackbar/Snackbar';
import {
  headerTitleWrapper,
  headerTitle,
  avatar,
  partyNumberText,
  contentWarp,
  partyDetailsWrap,
  addressDetails,
  commDetails,
  paymentDetails
} from './PartyStyles';
import { useDispatch } from 'react-redux';
import { setOpenBriefView } from 'store/briefview/briefViewDrawerSlice';

interface PartyBriefViewProps {
  partyNumber: string;
}

const PartyBriefView = (props: PartyBriefViewProps) => {
  const [isloading, setisloading] = useState(true);
  const theme = useTheme();
  const { t } = useTranslation<string>();
  const [partyDetails, setPartyDetails] = useState<IParty | undefined>();
  const service = new PartyService();
  const { showSnackBar } = useSnackBar();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getPartyDetails = () => {
    return service
      .get(props.partyNumber ?? '')
      .then((party: IParty) => {
        setPartyDetails(party);
        setisloading(false);
      })
      .catch((error: any) => {
        showSnackBar(error.message, 'error');
        setPartyDetails(undefined);
        setisloading(false);
      });
  };

  useEffect(() => {
    setisloading(true);
    props.partyNumber && getPartyDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.partyNumber]);

  const partyInfo = {
    partyNumber: partyDetails?.PartyNumber ?? '',
    dob: partyDetails?.BirthDt ?? '',
    gender: partyDetails?.GenderCd ?? '',
    partyType: partyDetails?.PartyTypeCd ?? '',
    postalCode: partyDetails?.Addr?.[0]?.PostalCode ?? '',
    address: partyDetails?.Addr?.[0]?.Addr1 ?? '',
    city: partyDetails?.Addr?.[0]?.City ?? '',
    emailAddress: partyDetails?.Communications?.map((item) => item.EmailAddr).join(' ') ?? '',
    ibanNumber: partyDetails?.Account?.[0]?.AccountNumberId?.Value ?? '',
    paymentMethod: partyDetails?.PaymentOption?.[0]?.MethodPaymentRefCd?._Desc ?? ''
  };

  const handleMoreDetailsClick = () => {
    dispatch(setOpenBriefView(false));
    navigate(`/party/partydetails/${props.partyNumber}/1`);
  };

  const preferredName =
    partyDetails?.PartyTypeCd === 'Organization'
      ? partyDetails?.Name ?? ''
      : `${partyDetails?.PreferredName ?? ''} ${partyDetails?.Surname ?? ''}`;

  return (
    <>
      {isloading ? (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isloading}
          onClick={() => {}}>
          <Loader></Loader>
        </Backdrop>
      ) : (
        <Box id='PartyBriefView' sx={{ p: 3 }}>
          {hasValues(partyInfo) ? (
            <>
              {(partyDetails?.Surname || partyDetails?.PartyNumber) && (
                <>
                  <Stack direction={'row'} spacing={1} alignItems='center'>
                    <Avatar sx={avatar}>
                      <Typography>{`${partyDetails?.PreferredName?.charAt(0) ?? partyDetails?.Name?.charAt(0)}${
                        partyDetails?.Surname?.charAt(0) ?? ''
                      }`}</Typography>
                    </Avatar>
                    <Stack direction='column' spacing={1}>
                      {preferredName && (
                        <>
                          <Box sx={headerTitleWrapper}>
                            <Tooltip title={preferredName}>
                              <Typography id='HeaderPreferredName' sx={headerTitle}>
                                {preferredName}
                              </Typography>
                            </Tooltip>
                          </Box>
                        </>
                      )}

                      {partyInfo.partyNumber && (
                        <>
                          <Typography id='HeaderPartyNumber' sx={partyNumberText}>
                            #{partyInfo.partyNumber}
                          </Typography>
                        </>
                      )}
                    </Stack>
                  </Stack>
                </>
              )}

              <Stack spacing={1} sx={contentWarp}>
                {(preferredName ||
                  partyInfo.partyNumber ||
                  partyInfo.dob ||
                  partyInfo.gender ||
                  partyInfo.partyType) && (
                  <>
                    <Typography sx={partyDetailsWrap}>{t('partyDetails')}</Typography>

                    <Grid container spacing={2}>
                      {preferredName && (
                        <>
                          <Grid item xs={6}>
                            <Typography sx={contentText}>{t('preferredName')}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography id='PreferredNameSurname' sx={contentText}>
                              {preferredName}
                            </Typography>
                          </Grid>
                        </>
                      )}

                      {partyInfo.partyNumber && (
                        <>
                          <Grid item xs={6}>
                            <Typography sx={contentText}>{t('partyNumber')}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography id='PartyNumber' sx={contentText}>
                              {partyInfo?.partyNumber}
                            </Typography>
                          </Grid>
                        </>
                      )}

                      {partyInfo?.dob && (
                        <>
                          <Grid item xs={6}>
                            <Typography sx={contentText}>{t('dob')}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography id='BirthDt' sx={contentText}>
                              {partyInfo?.dob}
                            </Typography>
                          </Grid>
                        </>
                      )}

                      {partyInfo?.gender && (
                        <>
                          <Grid item xs={6}>
                            <Typography sx={contentText}>{t('gender')}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography id='GenderCd' sx={contentText}>
                              {partyInfo?.gender}
                            </Typography>
                          </Grid>
                        </>
                      )}

                      {partyInfo?.partyType && (
                        <>
                          <Grid item xs={6}>
                            <Typography sx={contentText}>{t('partyType')}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography id='PartyTypeCd' sx={contentText}>
                              {partyInfo?.partyType}
                            </Typography>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </>
                )}

                {(partyInfo.postalCode || partyInfo.address || partyInfo.city) && (
                  <>
                    <Divider />
                    <Typography sx={addressDetails}>{t('addressDetails')}</Typography>
                    <Grid container spacing={2}>
                      {partyInfo.postalCode && (
                        <>
                          <Grid item xs={6}>
                            <Typography sx={contentText}>{t('postalCode')}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography id='PostalCode' sx={contentText}>
                              {partyInfo.postalCode}
                            </Typography>
                          </Grid>
                        </>
                      )}
                      {partyInfo.address && (
                        <>
                          <Grid item xs={6}>
                            <Typography sx={contentText}>{t('address')}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography id='Address' sx={contentText}>
                              {partyInfo.address}
                            </Typography>
                          </Grid>
                        </>
                      )}
                      {partyInfo.city && (
                        <>
                          <Grid item xs={6}>
                            <Typography sx={contentText}>{t('city')}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography id='City' sx={contentText}>
                              {partyInfo.city}
                            </Typography>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </>
                )}

                {(partyInfo.emailAddress || partyDetails?.Communications) && (
                  <>
                    <Divider />
                    <Typography sx={commDetails}>{t('communicationDeatails')}</Typography>

                    <Grid container spacing={2}>
                      {partyInfo.emailAddress && (
                        <>
                          <Grid item xs={6}>
                            <Typography sx={contentText}>{t('emailAddress')}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography id='EmailAddress' sx={contentText}>
                              {partyInfo.emailAddress}
                            </Typography>
                          </Grid>
                        </>
                      )}

                      {getPhoneNumber(partyDetails?.Communications)}
                    </Grid>
                  </>
                )}

                {(partyInfo.ibanNumber || partyInfo.paymentMethod) && (
                  <>
                    <Divider />
                    <Typography sx={paymentDetails}>{t('paymentDetails')}</Typography>
                    <Grid container spacing={2}>
                      {partyInfo.ibanNumber && (
                        <>
                          <Grid item xs={6}>
                            <Typography sx={contentText}>{t('ibanNumber')}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography id='AccountNumberId' sx={contentText}>
                              {partyInfo.ibanNumber}
                            </Typography>
                          </Grid>
                        </>
                      )}
                      {partyInfo.paymentMethod && (
                        <>
                          <Grid item xs={6}>
                            <Typography sx={contentText}>{t('paymentMethod')}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography id='PaymentMethod' sx={contentText}>
                              {partyInfo.paymentMethod}
                            </Typography>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </>
                )}
              </Stack>

              <Stack direction={'row'} spacing={1} justifyContent='space-between'>
                <Stack direction={'column'} spacing={1}>
                  {partyDetails?.PartyNumber && (
                    <Typography
                      id='MoreDetails'
                      variant='subtitle2'
                      display='block'
                      onClick={handleMoreDetailsClick}
                      sx={{
                        color: (theme) => theme.palette.secondary.main,
                        ml: 2,
                        cursor: 'pointer'
                      }}
                      gutterBottom>
                      {t('moreDetails')}
                    </Typography>
                  )}
                </Stack>
                <Stack direction={'column'} spacing={1}>
                  {partyDetails?.PartyNumber && (
                    <Typography
                      id='Edit'
                      variant='subtitle2'
                      display='block'
                      sx={{ color: (theme) => theme.palette.primary.main, ml: 2 }}
                      gutterBottom>
                      <Link
                        to={`/formrenderer/party/${partyDetails?.PartyNumber}`}
                        style={{ color: theme.palette.secondary.main }}>
                        {t('edit')}
                      </Link>
                    </Typography>
                  )}
                </Stack>
              </Stack>
            </>
          ) : (
            <Typography>{t('noData')}</Typography>
          )}
        </Box>
      )}
    </>
  );
};

export default PartyBriefView;
