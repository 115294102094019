import { useTheme } from '@mui/material/styles';
function IconInsuranceCar(_props: any) {
  const theme = useTheme();
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={_props.width ? _props.width : '38'}
      height={_props.height ? _props.height : '38'}
      viewBox='0 0 38 38'>
    
      <circle cx='19' cy='19' r='19' fill='rgba(0,70,255,.05)' data-name='Ellipse 467'></circle>
      <defs>
        <linearGradient id='duotoneGradient' x1='0%' y1='0%' x2='100%' y2='100%'>
          <stop offset='0%' style={{ stopColor: theme.palette.primary.main, stopOpacity: 1 }} />
          <stop offset='100%' style={{ stopColor: theme.palette.secondary.main, stopOpacity: 1 }} />
        </linearGradient>
      </defs>
      <path fill='none' d='M8.276 7.551h21.449V29H8.276z' data-name='Path 3647'></path>
      <path
        fill='url(#duotoneGradient)'
        d='M19.001 12.913a1.787 1.787 0 11-1.788 1.788 1.793 1.793 0 011.788-1.788m0 8.937c2.413 0 5.183 1.153 5.362 1.787H13.638c.206-.643 2.958-1.787 5.362-1.787m.001-10.724a3.575 3.575 0 103.575 3.575 3.574 3.574 0 00-3.575-3.575zm0 8.937c-2.386 0-7.15 1.2-7.15 3.575v1.788h14.3v-1.788c0-2.377-4.764-3.575-7.15-3.575z'
        data-name='Path 3648'></path>
    </svg>
  );
}

export default IconInsuranceCar;
