import { useTheme } from '@mui/material/styles';

function IconFeature6(props:any) {
  const theme = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || '20'}
      height={props.height || '20'}
      viewBox="0 0 640 512"
      aria-labelledby="icon-feature6-title"
      role="img"
    >
      <title id="icon-feature6-title">Feature Icon 6</title>
      <defs>
        <linearGradient id="truckmedical" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" style={{ stopColor: theme.palette.primary.main, stopOpacity: 1 }} />
          <stop offset="100%" style={{ stopColor: theme.palette.secondary.main, stopOpacity: 1 }} />
        </linearGradient>
      </defs>
      <path
        fill={'url(#truckmedical)'}
        d="M64 160C64 89.3 121.3 32 192 32l256 0c70.7 0 128 57.3 128 128l0 33.6c-36.5 7.4-64 39.7-64 78.4l0 48-384 0 0-48c0-38.7-27.5-71-64-78.4L64 160zM544 272c0-20.9 13.4-38.7 32-45.3c5-1.8 10.4-2.7 16-2.7c26.5 0 48 21.5 48 48l0 176c0 17.7-14.3 32-32 32l-32 0c-17.7 0-32-14.3-32-32L96 448c0 17.7-14.3 32-32 32l-32 0c-17.7 0-32-14.3-32-32L0 272c0-26.5 21.5-48 48-48c5.6 0 11 1 16 2.7c18.6 6.6 32 24.4 32 45.3l0 48 0 32 32 0 384 0 32 0 0-32 0-48z"
      />
    </svg>
  );
}

export default IconFeature6;
