import { useTheme } from '@mui/material/styles';
function IconHouse(_props: any) {
  const theme = useTheme();
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={_props.width ? _props.width : '20'}
      height={_props.height ? _props.height : '20'}
      viewBox='0 0 20 20'>
      <defs>
        <linearGradient id='duotoneGradient' x1='0%' y1='0%' x2='100%' y2='100%'>
          <stop offset='0%' style={{ stopColor: theme.palette.primary.main, stopOpacity: 1 }} />
          <stop offset='100%' style={{ stopColor: theme.palette.secondary.main, stopOpacity: 1 }} />
        </linearGradient>
      </defs>
      <path fill='none' d='M0 0h20v20H0z' data-name='Rectangle 2398'></path>
      <path
        fill='url(#duotoneGradient)'
        d='M10 2.5L5 6.317V5H3.333v2.592L.833 9.5l1.008 1.325 1.492-1.142V17.5h13.333V9.683l1.492 1.133L19.166 9.5zm5 13.333h-4.167V12.5H9.166v3.333H5V8.417L10 4.6l5 3.817z'
        data-name='Path 3660'></path>
    </svg>
  );
}

export default IconHouse;
