
import { Button, styled, Theme } from "@mui/material";
import { Link } from 'react-router-dom';
import { FormButtonProps } from "../Formrenderer/IFormDataGrid";

export const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width:'100%',
    zIndex:100,
    position:'relative'
  },
  truncate:{
    whiteSpace: 'nowrap', 
    overflow: 'hidden',   
    textOverflow: 'ellipsis',
  },
  addBoxWrapper:{
    minHeight: '155px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    zIndex: 1000,
    transform: 'translateZ(0px) translateY(-2px)' // Brings it up a bit and onto a new layer
  },
  cardWrapper:{
    minHeight: '150px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)' // Reduced shadow
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    px: 3,
    mt:2,
    width:'100%'
  },
  headerTitle: {
    pl: 1,
  },
  contentBox: {
    p: 3,
  },
  card: {
    maxWidth: '100%',
    height: '100%',
    background:(theme:Theme) => theme.palette.background.paper
  },
  link: {
    position: 'relative',
    zIndex: 1000,
    textDecoration: 'none',
  },
  iconBox: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    p: 2,
  },
  title: {
    textTransform: 'capitalize',
    color: (theme:Theme) => theme.palette.text.primary,
  },
  addButton: {
    width: '100%',  // Make the button take full width of its container
    height: '100%',  // Adjust the height as needed
    textAlign: 'center',
    padding: '16px',
  }
};

export const OverviewLink = styled(Link)({
  position: 'relative',
  zIndex: 1000,
  textDecoration: 'none',
  ':hover':{
    textDecoration: 'none'
  }
});

export const toolBar = { display: 'flex', justifyContent: 'flex-end', gap: '20px', py: 2 };

export const toolbarWrap = { width: '400px', display: 'inline-flex', justifyContent: 'flex-end' };

export const formButtonWrap = {
  bgcolor: (theme: Theme) => theme.palette.secondary.main,
  mr: 2,
  fontSize: '12px',
  cursor: 'pointer'
};

export const GridButton: React.FC<FormButtonProps> = ({ onClick, icon, label }) => (
  // eslint-disable-next-line react/jsx-no-undef
  <Button variant='contained' sx={formButtonWrap} onClick={onClick}>
    {icon}
    {label}
  </Button>
);

export const displayForm = { textTransform: 'capitalize', display: 'inline', fontSize: '14px' };
export const iconWrapper = { color: (theme: Theme) => theme.palette.secondary.main };
export const titleText = {
  whiteSpace: 'nowrap',
  width: '175px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  cursor: 'pointer',
  textTransform: 'capitalize',
  ml:2
};

export const uploadButton = {
  bgcolor: (theme: Theme) => theme.palette.secondary.main,
  mr: 2,
  fontSize: '12px',
  cursor: 'pointer'
};

export const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
  bgcolor: (theme: any) => theme.palette.secondary.main
});
