// LoaderService.ts
class LoaderService {
  private openBackdrop: boolean = false;
  private listeners: Function[] = [];

  setOpenBackdrop(value: boolean) {
    this.openBackdrop = value;
    this.notifyListeners();
  }

  getOpenBackdrop() {
    return this.openBackdrop;
  }

  subscribe(listener: Function) {
    this.listeners.push(listener);
  }

  unsubscribe(listener: Function) {
    this.listeners = this.listeners.filter(l => l !== listener);
  }

  private notifyListeners() {
    this.listeners.forEach(listener => listener(this.openBackdrop));
  }

  // Global loader function to wrap promises
  loader<T>(promise: Promise<T>): Promise<T> {
    this.setOpenBackdrop(true);
    return promise.finally(() => {
      this.setOpenBackdrop(false);
    });
  }
}

export const loaderService = new LoaderService();
