import { useEffect, useState, Fragment } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Avatar, Stack, Typography } from '@mui/material';
import IconSlideLeft from '../../atoms/Icons/IconSlideLeft';
import IconSlideRight from '../../atoms/Icons/IconSlideRight';
import { GetCompanyCloseLogos, GetCompanyOpenLogos, bottomMenu, menuItems } from 'utils/Constants';
import { useTranslation } from 'react-i18next';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import {
  Drawer,
  DrawerHeader,
  iconStyle2,
  itemBox,
  miniDrawerStyle,
  accountIconStyles,
  avatarStyles,
  iconButtonStyles,
  emailLinkStyles,
  emailTextStyles
} from './MiniDrawerStyle';
import { useKeycloak, getTokenProperty } from 'context/Keycloak/Keycloak';
import ArrowTooltip from 'component/atoms/Tooltip/ArrowTooltip';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Language from 'component/atoms/LanguageTranslation/Language';
import './MiniDrawerStyles.css';
import { useSelector } from 'react-redux';
import EmailIcon from '@mui/icons-material/Email';
import { State } from 'store/store';

export interface IThemeProps {
  activeLinkURl?: string;
  selectedIndex?: number;
}

export default function MiniDrawer(themeProps: IThemeProps) {
  const { logout } = useKeycloak();
  const { t } = useTranslation<string>();
  const currentTheme = useSelector((state: State) => state.themeConfig.currentTheme);
  const viewPane = useSelector((state: State) => state.themeConfig.viewPane);
  const theme = useTheme();
  const [open, setOpen] = useState(viewPane);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const username = getTokenProperty('preferred_username');
  const email = getTokenProperty('email');

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [selectedIndex, setSelectedIndex] = useState<any>(themeProps.selectedIndex || 0);

  const handleListItemClick = (_event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
    setSelectedIndex(index);
  };

  useEffect(() => {
    setSelectedIndex(themeProps.selectedIndex);
  }, [themeProps.selectedIndex]);

  const padding: any = !open && { p: 0 };

  return (
    <Box>
      <Drawer id='MiniDrawer' variant='permanent' open={open}>
        <DrawerHeader sx={padding}>
          {open ? <GetCompanyOpenLogos theme={currentTheme} /> : null}
          <IconButton sx={{ pt: 2 }} onClick={open ? handleDrawerClose : handleDrawerOpen}>
            {open ? (
              <Box sx={{ pt: 0.15 }}>
                <IconSlideLeft />
              </Box>
            ) : (
              <Stack direction={'column'} spacing={2}>
                <Box sx={{ pl: 1 }}>
                  <GetCompanyCloseLogos theme={currentTheme} />
                </Box>
                <IconSlideRight />
              </Stack>
            )}
          </IconButton>
        </DrawerHeader>

        <Divider />
        <List sx={miniDrawerStyle}>
          {menuItems
            .filter((menuItem) => menuItem.isVisible)
            .map((menuItem: any, index: number) => (
              <Fragment key={menuItem.text}>
                <ListItem sx={{ p: 1, display: 'block', width: '100%' }}>
                  {menuItem.subMenus && <Divider sx={{ margin: '8px 0' }} />}
                  <Link to={menuItem?.path as string}>
                    <ListItemButton
                      selected={selectedIndex === index}
                      onClick={(event) => handleListItemClick(event, index)}
                      className={menuItem?.isBrandcolor ? 'ListItemButton' : 'ListItemButtonStyle'}>
                      <ArrowTooltip title={t(menuItem.tooltip)} placement='right'>
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                            width: '36px'
                          }}>
                          {menuItem.icon}
                        </ListItemIcon>
                      </ArrowTooltip>
                      <ListItemText
                        primary={t(menuItem.text)}
                        sx={{ color: theme.palette.text.primary, opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </Link>
                </ListItem>
                {index === 2 && <Divider></Divider>}

                {menuItem.subMenus && open && (
                  <List sx={{ paddingLeft: theme.spacing(3) }}>
                    {menuItem.subMenus.map((subMenuItem: any) => (
                      <ListItem key={subMenuItem.text} disablePadding sx={{ display: 'block' }}>
                        <ListItemButton className='ListItemButton'>
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : 'auto',
                              justifyContent: 'center'
                            }}>
                            {subMenuItem.icon}
                          </ListItemIcon>
                          <ListItemText sx={{ color: theme.palette.text.primary, opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                )}
              </Fragment>
            ))}
        </List>
        <Divider />
        <Box sx={itemBox}>
          {open ? (
            <>
              <Stack
                direction={'row'}
                sx={{ justifyContent: 'flex-start', alignItems: 'flex-start', p: 1 }}
                spacing={1}>
                <Stack direction={'column'} sx={{ justifyContent: 'flex-start', alignItems: 'flex-start', pr: 1 }}>
                  {username === email ? (
                    <Tooltip title={username}>
                      <Typography component={'span'} sx={emailTextStyles}>
                        <AccountCircleIcon sx={accountIconStyles} />
                        {username}
                      </Typography>
                    </Tooltip>
                  ) : (
                    <>
                      <Tooltip title={username}>
                        <Typography component={'span'} sx={emailTextStyles}>
                          <AccountCircleIcon sx={accountIconStyles} />
                          {username}
                        </Typography>
                      </Tooltip>
                      <Tooltip title={email}>
                        <Typography component={'span'} sx={emailTextStyles}>
                          <a href={`mailto:${email}`} style={emailLinkStyles}>
                            <EmailIcon sx={accountIconStyles} />
                            {email}
                          </a>
                        </Typography>
                      </Tooltip>
                    </>
                  )}
                </Stack>
                <Avatar sx={avatarStyles}>
                  <Typography>{username[0]}</Typography>
                  <Typography>{username[1]}</Typography>
                </Avatar>
              </Stack>
            </>
          ) : (
            <Avatar sx={avatarStyles}>
              {username[0]}
              {username[1]}
            </Avatar>
          )}
        </Box>
        <Divider />
        <Box sx={itemBox}>
          {open ? (
            <>
              <Stack sx={{ justifyContent: 'space-between' }} direction='row' spacing={2}>
                <Tooltip title={t(bottomMenu[0]?.tooltip as string)}>
                  <IconButton onClick={() => logout()} sx={iconButtonStyles} color='primary'>
                    <LogoutIcon sx={accountIconStyles} />
                  </IconButton>
                </Tooltip>

                <Language title={t(bottomMenu[2]?.tooltip as string)} />

                <Tooltip title={t(bottomMenu[1]?.tooltip as string)}>
                  <IconButton
                    sx={{ ...iconStyle2, ...iconButtonStyles }}
                    onClick={() => setSelectedIndex(bottomMenu[1]?.index)}
                    color='primary'>
                    <Link
                      className={selectedIndex === bottomMenu[1]?.index ? 'activeLink' : 'normalLink'}
                      to='/settings'>
                      <SettingsOutlinedIcon sx={accountIconStyles} />
                    </Link>
                  </IconButton>
                </Tooltip>
              </Stack>
            </>
          ) : null}
        </Box>
      </Drawer>
    </Box>
  );
}
