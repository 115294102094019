import { Box } from '@mui/material';
import React, { useState, useEffect, ReactNode } from 'react';
import DescriptionTwoToneIcon from '@mui/icons-material/DescriptionTwoTone';
import { chromeHeader, chromeWindow, description, messageWindow, overlay } from './ErrorCoverStyle';
import { useTranslation } from 'react-i18next';
import AppConfigService from 'entities/AppConfig/AppConfigService';

interface NetworkErrorDetectorProps {
  children: ReactNode;
}

const NetworkErrorDetector: React.FC<NetworkErrorDetectorProps> = ({ children }) => {
  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);
  const [isServerUp, setIsServerUp] = useState<boolean>(true);
  const [initialCheckComplete, setInitialCheckComplete] = useState<boolean>(false);
  const service = new AppConfigService();
  const { t } = useTranslation();

  function removeWebpackOverlay() {
    const overlay = document.getElementById('webpack-dev-server-client-overlay');
    if (overlay) overlay.remove();
  }

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    const checkServerStatus = async () => {
      try {
        await service.get();
        setIsServerUp(true);
      } catch (error) {
        setIsServerUp(false);
        setTimeout(removeWebpackOverlay, 100);
      } finally {
        setInitialCheckComplete(true); 
      }
    };

    checkServerStatus();

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!initialCheckComplete) {
    return <Box>{t('loading')}</Box>;
  }

  return (
    <>
      {(!isOnline || !isServerUp) && (
        <Box className='relative h-screen bg-gray-200'>
          <Box sx={overlay}>
            <Box sx={chromeWindow}>
              <Box sx={chromeHeader}>{t('networkStatus')}</Box>
              {!isOnline ? (
                <Box sx={messageWindow}>
                  <DescriptionTwoToneIcon sx={{ fontSize: 80 }} />
                  <Box sx={description}>{t('netConnection')}</Box>
                  <Box sx={description}>{t('supportTeam')}</Box>
                </Box>
              ) : (
                <Box sx={messageWindow}>
                  <DescriptionTwoToneIcon sx={{ fontSize: 80 }} />
                  <Box sx={description}>{t('pageRefresh')}</Box>
                  <Box sx={description}>{t('supportTeam')}</Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      )}
      {isOnline && isServerUp && children}
    </>
  );
};

export default NetworkErrorDetector;
