import { formioEventHandlerType } from './attachEventHandlerToFormioInstance';
import {
  getCustomProperty,
  getGridValueFormInstance,
  getTags,
  getValueBySubPath
} from './CustomPropertySupport/helperFunctions';

//==============================================================
// Types
//==============================================================

type CoverageRecord = {
  index: number;
  value: string;
};

//==============================================================
// private variables
//==============================================================

const getCoverageObject = (prefix: string, coverageObject: any) => {
  const coverageAvailable = getValueBySubPath(coverageObject, 'CoverageAvailable');
  return prefix
    .split('.')
    .reverse()
    .reduce<object>((prev, key) => ({ [key]: prev }), { Coverage: coverageAvailable });
};

const addCoverage = (
  data: any,
  gridValue: any[],
  coverage_record: CoverageRecord[],
  extCoverageTypeCd: string,
  prefix_key: string
) => {
  const coverageObject = data.CoveragesAvailable.find(
    (coverage: any) => extCoverageTypeCd === getValueBySubPath(coverage, 'ExtCoverageTypeCd')
  );
  gridValue.push(getCoverageObject(prefix_key, coverageObject));
  coverage_record.push({ index: coverage_record.length, value: extCoverageTypeCd });
};

const setCoveragePremiums = (instance: any, changedInstance: any) => {
  const data = changedInstance.root.submission.data;
  const array_path: string = changedInstance.component.properties.array_path;
  const prefix_key = array_path.split('.CoverageAvailable')[0] ?? '';
  const coverage_record: CoverageRecord[] = [];
  const gridValue: any[] = [];
  let totalCoverage = 0.0;

  changedInstance.getValue().forEach((coverage: any) => {
    const extCoverageTypeCd: string = getValueBySubPath(coverage, 'ExtCoverageTypeCd') as string;
    data[`${extCoverageTypeCd}-Premium`] = getValueBySubPath(coverage, 'NetAmt.Amt');

    if (data[extCoverageTypeCd]) {
      addCoverage(data, gridValue, coverage_record, extCoverageTypeCd, prefix_key);
      totalCoverage = totalCoverage + parseFloat(data[`${extCoverageTypeCd}-Premium`]);
    }
  });

  instance.setValue(gridValue);
  data.coverage_record = coverage_record;
  data.totalCoverage = totalCoverage;
};

const updateCoverageRecord = (data: any, gridValue: any[]) => {
  const coverage_record: CoverageRecord[] = [];

  gridValue.forEach((coverageObject, index) => {
    const extCoverageTypeCd = getValueBySubPath(coverageObject, 'ExtCoverageTypeCd') as string;
    coverage_record.push({ index, value: extCoverageTypeCd });
    data[extCoverageTypeCd] = true;
  });

  data.coverage_record = coverage_record;
};

const onUserInput = (instance: any, CoveragesAvailable: any[]) => {
  const data = instance.root.submission.data;
  const array_path: string = instance.component.properties.array_path;
  const prefix_key = array_path.split('.Coverage')[0] ?? '';

  const coverage_record: CoverageRecord[] = [];
  const gridValue: any[] = [];
  let totalCoverage = 0.0;

  CoveragesAvailable.forEach((coverage: any) => {
    const extCoverageTypeCd: string = getValueBySubPath(coverage, 'ExtCoverageTypeCd') as string;
    const premium = getValueBySubPath(coverage, 'NetAmt.Amt') as string;
    if (data[extCoverageTypeCd]) {
      addCoverage(data, gridValue, coverage_record, extCoverageTypeCd, prefix_key);
      totalCoverage = totalCoverage + parseFloat(premium);
    }
  });

  instance.setValue(gridValue);
  data.coverage_record = coverage_record;
  data.totalCoverage = totalCoverage;
};

//==============================================================
// public variables
//==============================================================

export const coverageChangeEventHandler: formioEventHandlerType = (eventData, instance, _emitEventName) => {
  const changedInstance = eventData.changed?.instance;
  const gridValue = getGridValueFormInstance(instance);
  const coverage_record: CoverageRecord[] = eventData.data?.coverage_record ?? [];
  const coverageAvailableKey = getCustomProperty(instance.component, 'array_path') + 'Available';
  const CoveragesAvailable = (eventData?.data ?? {})[coverageAvailableKey] ?? [];

  if (changedInstance && getTags(changedInstance.parent.component).includes('Coverage')) {
    onUserInput(instance, CoveragesAvailable);
  } else if ((changedInstance?.component.properties.array_path ?? '').includes('CoverageAvailable')) {
    eventData.data[coverageAvailableKey] = changedInstance.getValue();
    setCoveragePremiums(instance, changedInstance);
  } else if (coverage_record.length !== gridValue.length) {
    updateCoverageRecord(eventData.data, gridValue);
  }
};
