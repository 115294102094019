//import * as React from 'react';
//import Smartsearch from '../../component/organisms/Smartsearch/Smartsearch';
import {Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { accountMenuItems, accountMenuWithCancelItem } from 'utils/Constants';
//import MuiRadioButtonsGroup from 'component/organisms/RadioButtonsGroup/RadioButtonsGroup';
//import MuiAntEyeSwitch from 'component/organisms/Switches/AntEyeSwitch';
import ToolTip from 'component/atoms/Tooltip/WarningToolTip';
import { Emailtypefield } from 'component/organisms/Emailtypefield/Emailtypefield';
import { InputFormTextField } from 'component/organisms/Inputfield/Inputfield';
import { Dropdownpicker } from 'component/organisms/Dropdownpicker/Dropdownpicker';
import { Palletes } from 'temp/Typography/Typography';
import { Icons } from 'component/atoms/Icons/MuiIcons';
//import MuiLoginModal from 'component/organisms/Modals/LoginModal';
//import MuiClosableModal from 'component/organisms/Modals/ClosableModal';
import { AddButton } from 'component/atoms/Buttons/AddButton';
import Datepickercalendar from 'component/organisms/Datepickercalendar/Datepickercalendar';
import { InputPassword } from 'component/organisms/InputPassword/InputPassword';
//import NoticeCard from 'component/atoms/Cards/NoticeCard';
import { useTranslation } from 'react-i18next';
import InternationalPhone from 'component/organisms/InternationalPhone/InternationalPhone';
import { Togglebuttons } from 'component/organisms/Togglebuttons/Togglebuttons';
import FileUpload from 'component/organisms/Fileupload/FileUpload';
import SearchandAddtag from 'component/organisms/SearchandAddtag/SearchandAddtag';
import AccountMenu from 'component/organisms/Menus/AccountMenu';
import AccountMenuWithCancel from 'component/organisms/Menus/AccountMenuWithCancel';
//import EyeSwitch from 'component/organisms/Switches/EyeSwitch';
import ArrowTooltip from 'component/atoms/Tooltip/ArrowTooltip';
import { StyledButton } from 'component/atoms/Buttons/ButtonStyle';
import { useDispatch, useSelector } from 'react-redux';
import { setOpenBriefView } from 'store/briefview/briefViewDrawerSlice';
import { Settings } from 'component/pages/Settings/ThemeSettings';
import { State } from 'store/store';

// import { SearchButtonWrapper, policyInnerWrapper, searchBarWrapper } from 'component/pages/Party/PartyStyles';
// import Smartsearch from 'component/organisms/Smartsearch/Smartsearch';
// import {
//   ProfileHeader,
//   SearchButtonWrapper,
//   boxDesscription,
//   boxTitle,
//   closeIconWrapper,
//   filterWrapper,
//   partyDetailsHeader,
//   policyDetails,
//   policyInnerWrapper,
//   policyWrapper,
//   searchBarWrapper
// } from './PartyStyles';
// import { StyledButton } from 'component/atoms/Buttons/ButtonStyle';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select, { SelectChangeEvent } from '@mui/material/Select';
export const DesignSystem = () => {
  const { t } = useTranslation<string>();
  const viewComponent = useSelector((state: State) => state.themeConfig.viewComponent);
  const handleChange = (event: any) => {
    console.log(event);
  };

  return (
    <div style={{ overflow: 'auto', width: '100%' }}>
      <Box>
        <Settings />       
      </Box>
      {viewComponent ? (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'start',
              px: 3,
              py: 5,
              flexDirection: 'row'
            }}>
            <Box sx={{ display: 'flex', width: '50%', p: 2, flexDirection: 'column' }}>
              <Box>
                <Togglebuttons label={t('customerIsReporter')}></Togglebuttons>
              </Box>
              <Box>
                <InputFormTextField label={t('firstName')}></InputFormTextField>
              </Box>
              <Box>
                <Dropdownpicker
                  value=''
                  handleChange={handleChange}
                  customMenuOptions={[]}
                  label={t('dropdownPicker') as string}></Dropdownpicker>
              </Box>
              <Box>
                <InputFormTextField label={t('lastName')}></InputFormTextField>
              </Box>
              <Box sx={{ mb: 2 }}>
                <Datepickercalendar label={t('pickDate') as string}></Datepickercalendar>
              </Box>
              <Box>
                <InputPassword></InputPassword>
              </Box>
              <Box>
                <InternationalPhone></InternationalPhone>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', width: '50%', p: 2, flexDirection: 'column' }}>
              <Box>
                <InputFormTextField label={t('firstName')} error={true}></InputFormTextField>
              </Box>
              <Box>
                <InputFormTextField label={t('firstName')} success={true}></InputFormTextField>
              </Box>
              <Box>
                <InputFormTextField label={t('firstName')} info={'firstName'}></InputFormTextField>
              </Box>
              <Box>{/*<MuiTextarea label={t('incidentdescription')}></MuiTextarea>*/}</Box>
              <Box>
                <FileUpload label={t('upload')}></FileUpload>
              </Box>
              <Box>
                <SearchandAddtag label={t('searchTag')}></SearchandAddtag>
              </Box>
              <Box sx={{ mb: 3 }}>
                <Emailtypefield></Emailtypefield>
              </Box>
              {/*<Box sx={{ mb: 2 }}>
            <Smartsearch label={t('smartSearch')}></Smartsearch>
      </Box>*/}
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'start',
              px: 3,
              py: 5,
              flexDirection: 'column'
            }}>
            <DisplayComponents></DisplayComponents>
          </Box>
          <Palletes></Palletes>
          <Icons />
        </>
      ) : (
        ''
      )}
    </div>
  );
};

export const DisplayComponents = () => {
  const { t } = useTranslation<string>();

  //BriefViewDrawer code
  const dispatch = useDispatch();

  const handleDrawerOpen = () => {
    dispatch(setOpenBriefView(true));
  };

  // Radio Group Gender code start
  //const [selectedGenderValue, setSelectedGenderValue] = React.useState('man'); // Set an initial value
  /*
  const handleRadioChange = (value: any) => {
    setSelectedGenderValue(value);
    alert(value);
  };*/
  // Radio Group Gender code end

  //AntSwitch code start
  // const [checkedAntSwitch, setCheckedAntSwitch] = React.useState(true);
  /*
  const antSwitchhandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedAntSwitch(event.target.checked);
    alert('AntSwitch is getting on : ' + event.target.checked);
  };*/
  // const [searchVal, setSearchVal] = useState('');
  // const handleInputChange = (val: any) => {
  //   setSearchVal(val);
  // };
  // const handleIconClick = () => {
  //   handlePolicySearch(searchVal, filter);
  // };

  // const handleFilterChange = (event: SelectChangeEvent) => {
  //   event.target.value === 'partyNumber' ? setPartyView(true) : setPartyView(false);
  //   setPartiesData([]);
  //   setPartyDetails(undefined);
  //   setFilter(event.target.value as string);
  // };

  return (
    <Box
      sx={{
        p: 2,
        overflowY: 'auto',
        justifyContent: 'left',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%'
      }}>
      {/* <Box sx={policyInnerWrapper}>
        <Box sx={searchBarWrapper}>
          <Smartsearch
            searchVal={searchVal}
            handleInputChange={handleInputChange}
            handleIconClick={handleIconClick}></Smartsearch>
        </Box>
        <Box sx={filterWrapper}>
          <FormControl size='small' fullWidth>
            <Select value={filter} onChange={handleFilterChange}>
              <MenuItem value={'partyNumber'}>{t('partyNumber')}</MenuItem>
              <MenuItem value={'Name'}>{t('partyName')}</MenuItem>
              <MenuItem value={'EmailAddr'}>{t('partyEmail')}</MenuItem>
              <MenuItem value={'PostalCode'}>{t('partyPostalCode')}</MenuItem>
              <MenuItem value={'StreetName'}>{t('partyStreetName')}</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box sx={SearchButtonWrapper}>
          <StyledButton
            sx={{ p: '8px' }}
            onClick={() => {
              navigate('/party');
              handlePolicySearch(searchVal, filter);
            }}
            size='small'
            variant='contained'>
            {t('search')}
          </StyledButton>
        </Box>
      </Box> */}
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
        <Box sx={{ width: '30%', display: 'flex', pr: '30px', flexDirection: 'column' }}>
          <Box sx={{ mb: 2 }}>
            <ToolTip title={t('warningTooltipTitle')} message={t('warningTooltipMsg')} placement='right'>
              <Typography component={'span'}> {t('warningTooltipLabel')} </Typography>
            </ToolTip>
          </Box>
          <Box sx={{ mb: 2 }}>
            <ArrowTooltip title={t('arrowTooltipTitle1')} placement='right'>
              <Stack direction='column' spacing={1} alignItems='flex-start'>
                <AccountMenu menuItems={accountMenuItems} buttonLabel={t('dropDownMenuButtonLabel')} />
              </Stack>
            </ArrowTooltip>
          </Box>
          <Box sx={{ mb: 2 }}>
            <ArrowTooltip title={t('arrowTooltipTitle2')} placement='top'>
              <>
                {' '}
                <AccountMenuWithCancel
                  menuItems={accountMenuWithCancelItem}
                  buttonLabel={t('dropDownMenuButtonLabelWithCancel')}
                />
              </>
            </ArrowTooltip>
          </Box>
          <Stack direction='column' spacing={2}>
            <Box sx={{ mb: 2 }}>
              <Typography component={'span'}>{t('buttonOptions')}</Typography>
            </Box>

            <Box sx={{ mb: 2 }}>
              <StyledButton variant='contained' onClick={handleDrawerOpen}>
                {t('contained')}
              </StyledButton>
            </Box>

            <Box sx={{ mb: 2 }}>
              <AddButton variant='contained' buttonText={t('add') as string} isDisable={false} />
            </Box>

            <Box sx={{ mb: 2 }}>
              <AddButton variant='contained' buttonText={t('addDisabled') as string} isDisable={true} />
            </Box>

            <Box sx={{ mb: 2 }}>
              <StyledButton variant='outlined'>{t('contained')}</StyledButton>
            </Box>

            <Box sx={{ mb: 2 }}>
              <AddButton variant='outlined' buttonText={t('add') as string} isDisable={false} />
            </Box>

            <Box sx={{ mb: 2 }}>
              <AddButton variant='outlined' buttonText={t('addDisabled') as string} isDisable={true} />
            </Box>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};
