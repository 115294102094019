import { Box, Typography } from '@mui/material';

import './Underconstruction.css';
import { useTranslation } from 'react-i18next';
import IconFiles from 'component/atoms/Icons/IconFiles';

const Underconstruction = () => {
  const { t } = useTranslation();
  return (
    <Box sx={{ width: '100%' }}>
      <div className='svg-container'>
        <IconFiles/>
        <Typography component={'span'} sx={{ fontSize: '20px', color: '#a565ac', textTransform: 'uppercase', mt: 4 }}>
        {t('pageUnderConstruction')}
        </Typography>
      </div>
    </Box>
  );
};

export default Underconstruction;
